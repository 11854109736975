import { useCallback, useEffect, useMemo, useState} from "react";
import PageTitle from "../../../../../components/PageTitle";
import { userPreferences, updateUserPreferences, userPreferencesV1, updateUserPreferencesV1 } from "../../../../../services/settings";
import { useLocation } from "react-router-dom";
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im' ;
import customToast from "../../../../../components/CustomToast";
import { getSubscriptionDetails } from "../../../../../services/payment";
import { useSelector } from "react-redux";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import { PiDotsThreeCircleLight } from "react-icons/pi";

const UserControls = () => {
  
  const location = useLocation();
  
  const [loading, setLoading] = useState(true);
  const [tracker, setTracker] = useState(false);
  const [punching, setPunching] = useState(false);
  const [checkInMandatory, setCheckInMandatory] = useState(false);
  const [CRMandatory, setCRMandatory] = useState(false);
  const [backlogBlocking, setBacklogBlocking] = useState(false);
  const [fencing, setFencing] = useState(false);
  const [tourlimit, setTourLimit] = useState(false);
  const [subsDetails, setSubsDetails] = useState(null);
  // const [autoDcsApproval, setAutoDcsApproval] = useState(null);
  // const [autoCallPlanningApproval, setAutoCallPlanningApproval] = useState(null);
  const [dcsFilter, setDCSFilter] = useState({});
  const [deletionRequest, setDeletionRequest] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  // const [dcsFilterAll, setDCSFilterAll] = useState(null)
  // const [dcsFilterArea, setDCSFilterArea] = useState(null)
  // const [dcsFilterHQ, setDCSFilterHQ] = useState(null)
  // const [dcsFilterMyList, setDCSFilterMyList] = useState(null)
  // const [dcsFilterPlannedList, setDCSFilterPlannedList] = useState(null)
  const [workInMandatory, setWorkInMandatory] = useState(null);
  const [callPlanningMandatory, setCallPlanningMandatory] = useState(null);
  const [autoApproval, setAutoApproval] = useState({});
  const [ type, setType ] = useState("") 


  useEffect(()=>{
    
    userPreferences({selectedUser : location?.state?._id}).then(({data})=>{
      if(data?.punching)setPunching(data?.punching);
      if(data?.tracker)setTracker(data?.tracker);
      if(data?.checkInMandatory)setCheckInMandatory(data?.checkInMandatory);
      if(data?.CRMandatory)setCRMandatory(data?.CRMandatory);
      if(data?.backlogBlocking)setBacklogBlocking(data?.backlogBlocking);
      if(data?.fencing)setFencing(data?.fencing);
      if(data?.tourlimit)setTourLimit(data?.tourlimit);
      // if(data?.autoDcsApproval)setAutoDcsApproval(data.autoDcsApproval) ;
      // if(data?.autoCallPlanningApproval)setAutoCallPlanningApproval(data.autoCallPlanningApproval) ;
      // if(data?.dcsFilter?.all)setDCSFilterAll(data.dcsFilter.all);
      // if(data?.dcsFilter?.area)setDCSFilterArea(data.dcsFilter.area);
      // if(data?.dcsFilter?.hq)setDCSFilterHQ(data.dcsFilter.hq);
      // if(data?.dcsFilter?.myList)setDCSFilterMyList(data.dcsFilter.myList);
      // if(data?.dcsFilter?.plannedList)setDCSFilterPlannedList(data.dcsFilter.plannedList);
      if(data?.deletionRequest)setDeletionRequest(data.deletionRequest);
      if(data?.workInMandatory)setWorkInMandatory(data.workInMandatory);
      if(data?.callPlanningMandatory)setCallPlanningMandatory(data.callPlanningMandatory);
      if(data?.autoApproval)setAutoApproval(data.autoApproval);
      if(data?.dcsFilter)setDCSFilter(data.dcsFilter);

      setLoading(false);
    }).catch((err)=> customToast.error("Unable to load preferences")) ;
  },[location])

  useEffect(() =>{
    const fetchData = async() =>{
      await getSubscriptionDetails()
        .then((res)=>{
          setSubsDetails(res.data);
        })
        .catch(() =>{
          customToast.error("Error occurred !");
        })
    };
    fetchData();
  },[]);
  
  const handleSubmit = ()=>{
    if(loading)return ;
    setLoading(true);
    const details = {
      userId: location?.state?._id ,
      name:location?.state?.name,
      tracker,
      punching,
      checkInMandatory,
      CRMandatory,
      backlogBlocking,
      fencing,
      tourlimit,
      // autoDcsApproval,
      // autoCallPlanningApproval,
      // dcsFilter: {
      //   all: dcsFilterAll,
      //   area: dcsFilterArea,
      //   hq: dcsFilterHQ,
      //   myList: dcsFilterMyList,
      //   plannedList: dcsFilterPlannedList
      // },
      dcsFilter,
      deletionRequest,
      workInMandatory,
      callPlanningMandatory,
      autoApproval
    }
    updateUserPreferences(details).then(()=>customToast.success("User Prefrences Updated Successfully"))
    .catch(err=>customToast.error("Sorry Something went wrong"))
    .finally(()=>setLoading(false)) ;
  }

  const Checkbox = ({state, stateManager})=>{
    if(loading)return null ;
    return state ? (
      <ImCheckboxChecked className="icon-color-tertiary cursor-pointer" onClick={(e) => {
        e.preventDefault();
        stateManager(false);
      }}/>
    ):(
      <ImCheckboxUnchecked className="icon-color-tertiary cursor-pointer" onClick={(e) => {
        e.preventDefault();
        stateManager(true);
      }}/>
    )
  }

  const Checkbox1 = ({name, state, stateManager})=>{
    if(loading)return null ;
    return state ? (
      <ImCheckboxChecked className="icon-color-tertiary cursor-pointer" onClick={(e) => {
        e.preventDefault();
        stateManager((prev) => ({ ...prev, [name]: false }));
      }}/>
    ):(
      <ImCheckboxUnchecked className="icon-color-tertiary cursor-pointer" onClick={(e) => {
        e.preventDefault();
        stateManager((prev) => ({ ...prev, [name]: true }));
      }}/>
    )
  }


// EditPopup 

const EditPopup = () => {

  return (
    <section className="edit-popup edit-popup-top">
      <div className="edit-content edit-content-top custom-scrollbar custom-scrollable" style={{ maxHeight: '80vh'}}>
        <div className="edit-popup__heading">
          <h2 className="web-app__heading">
            {
              type === "DCSFilter" && <span>DCS LIST FILTER</span>
            }
            {
              type === "AutoApproval" && <span>Auto Approval</span>
            }
          </h2>
          <RiCloseCircleLine
            className="edit-popup__close"
            onClick={() => setShowEdit(false)}
          />
        </div>

        {/* <div className="d-flex flex-row align-items-center justify-content-between mt-5">
          <div className="web-app__heading">All</div>
          <Checkbox state={dcsFilterAll} stateManager={setDCSFilterAll} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-5">
          <div className="web-app__heading">Area</div>
          <Checkbox state={dcsFilterArea} stateManager={setDCSFilterArea} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-5">
          <div className="web-app__heading">HQ</div>
          <Checkbox state={dcsFilterHQ} stateManager={setDCSFilterHQ} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-5">
          <div className="web-app__heading">MyList</div>
          <Checkbox state={dcsFilterMyList} stateManager={setDCSFilterMyList} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mt-5">
          <div className="web-app__heading">PlannedList</div>
          <Checkbox state={dcsFilterPlannedList} stateManager={setDCSFilterPlannedList} />
        </div> */}

        { type === "DCSFilter" && 
          Object.entries(dcsFilter).map(([key, value]) => (
            <div className="d-flex flex-row align-items-center justify-content-between mt-5">
              <div className="web-app__heading" >{key}</div>
              <Checkbox1 name={key} state={value} stateManager={setDCSFilter} />
            </div>
          ))
        }

         { type === "AutoApproval" &&
          Object.entries(autoApproval).map(([key, value]) => (
            <div className="d-flex flex-row align-items-center justify-content-between mt-5">
              <div className="web-app__heading" >{key}</div>
              <Checkbox1 name={key} state={value} stateManager={setAutoApproval} />
            </div>
          ))
        }
         <div className="mb-2">
            <button
              type="button"
              onClick={handleSubmit}
              className="button-blue-gradient  mt-5 m-auto"
            >
              Submit
            </button>
          </div>
        </div>
    </section>
  );
};

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle hierarchy={true} title={`set Preferences For "${location?.state?.name}"`} />

        <section className="area-creation-content__info">
          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="d-flex flex-row gap-3">
            <div className="web-app__heading">Punching</div>
            {(subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ? 
            null
            :
            <div className="ml-10" style={{color:"red"}}>{"(*Only Available in Advance Plan)"}</div>
            }
            </div>
            {(subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ? 
            <Checkbox state={punching} stateManager={setPunching} />
            :
            <ImCheckboxUnchecked style={{color:"grey", cursor:"not-allowed"}} size={22} />}
          </div>
          <h4 className="mt-1 recommend" >
            This will allow the user to Check-In and Check-Out from their app during their working hours.
          </h4>
          {
            location?.state?.des === 121 ? null : <>
            <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="d-flex flex-row gap-3">
              <div className="web-app__heading">Tracker</div>
              {(subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ? 
              null
              :
              <div className="ml-10" style={{color:"red"}}>{"(*Only Available in Advance Plan)"}</div>
              }
              </div>
              {(subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ? 
              <Checkbox state={tracker} stateManager={setTracker} />
              :
              <ImCheckboxUnchecked style={{color:"grey", cursor:"not-allowed"}} size={22} />}
            </div>
             <h4 className="mt-1 recommend" >
             This will allow the managers to see the Live Location of Checked-In users on Google Map.
           </h4>
            </>
          }
          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
          <div className="d-flex flex-row gap-3">
            <div className="web-app__heading">Check In Mandatory</div>
            {(subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ? 
            null
            :
            <div className="ml-10" style={{color:"red"}}>{"(*Only Available in Advance Plan)"}</div>
            }
            </div>
            {(subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ? 
            <Checkbox state={checkInMandatory} stateManager={setCheckInMandatory} />
            :
            <ImCheckboxUnchecked style={{color:"grey", cursor:"not-allowed"}} size={22} />}
          </div>
          <h4 className="mt-1 recommend" >
            This feature makes it compulsory to be Checked-In for submitting Daily Call Reports.
          </h4>

          {
            (subsDetails?.length > 0 && subsDetails[0]?.gps === true) === true ?
            null :
            <>
                <div className="d-flex flex-row align-items-center justify-content-between mt-5">
                  <div className="web-app__heading">WorkIn Mandatory</div>
                  <Checkbox state={workInMandatory} stateManager={setWorkInMandatory} />
                </div>
                <h4 className="mt-1 recommend" >
                  This feature makes it compulsory to be Checked-In for submitting Daily Call Reports.
                </h4>
            </>
          }

          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Call Planning Mendatory</div>
            <Checkbox state={callPlanningMandatory} stateManager={setCallPlanningMandatory} />
          </div>
          <h4 className="mt-1 recommend" >
            It allows automatic approval of call planning reports for this user. When a user creates new call report it won't go for approval to admin or manager.
          </h4>

          
          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Call Report Mandatory For Expense</div>
            <Checkbox state={CRMandatory} stateManager={setCRMandatory} />
          </div>
          <h4 className="mt-1 recommend" >
            This feature will allow the Expense to be submitted automatically once the Call Report has been approved.
          </h4>
          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Block Backlog Reporting</div>
            <Checkbox state={backlogBlocking} stateManager={setBacklogBlocking} />
          </div>
          <h4 className="mt-1 recommend" >
            This feature allows an admin to prohibit a user from submitting backlog reports.
          </h4>
          
          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="d-flex flex-row gap-3">
            <div className="web-app__heading">Geo Fencing</div>
            {(subsDetails?.length > 0 && subsDetails[0]?.fencing === true) === true ? 
            null
            :
            <div className="ml-10" style={{color:"red"}}>{"(*Only Available in Geo Fencing Plan)"}</div>
            }
            </div>
            {(subsDetails?.length > 0 && subsDetails[0]?.fencing === true) === true ? 
            <Checkbox state={fencing} stateManager={setFencing} />
            :
            <ImCheckboxUnchecked style={{color:"grey", cursor:"not-allowed"}} size={22} />}
          {/* </div> */}
            {/* <div className="web-app__heading">Geo Fencing</div>
            <Checkbox state={fencing} stateManager={setFencing} /> */}
          </div>
          <h4 className="mt-1 recommend" >
            If Geo Fencing is turned on then user will only be able to add call report when he is in fixed range of the Doctor's Location.
          </h4>
          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Tour Limit</div>
            <Checkbox state={tourlimit} stateManager={setTourLimit} />
          </div>
          <h4 className="mt-1 recommend" >
            If Tour Limit is turned on then user won't be able to create tour programs for a particular route more than specified by Admin.
          </h4>
          {/* <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Automatic DCS Approval</div>
            <Checkbox state={autoDcsApproval} stateManager={setAutoDcsApproval} />
          </div>
          <h4 className="mt-1 recommend" >
            It allows automatic approval of doctor chemist and stockist for this user. When a user creates new DCS it won't go for approval to admin or manager.
          </h4> */}
          {/* <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Automatic Call Planning Approval</div>
            <Checkbox state={autoCallPlanningApproval} stateManager={setAutoCallPlanningApproval} />
          </div>
          <h4 className="mt-1 recommend" >
            It allows automatic approval of call planning reports for this user. When a user creates new call report it won't go for approval to admin or manager.
          </h4> */}
          {
            location?.state?.des === 121 ? null :
            <>
              <div className="d-flex flex-row align-items-center justify-content-between mt-5">
                <div className="web-app__heading">Deletion Request</div>
                <Checkbox state={deletionRequest} stateManager={setDeletionRequest} />
              </div>
              <h4 className="mt-1 recommend" >
              If Deletion Request is turned on for a particular manager, he/she will be able to see the Deletion Request panel in their Approval Section. And will be able to “Approve” or “Reject” the requests to delete Doc/Chem/Stk.
              </h4>
            </>
          }

          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">DCS List Filter</div>
            <PiDotsThreeCircleLight
              className="icon-color-tertiary cursor-pointer"
              onClick={() => 
                {
                  setType("DCSFilter")
                  setShowEdit(true)
                }
                }
            />
          </div>
          <h4 className="mt-1 recommend" >
          This feature allows access to different lists Medorn App has, to sort the entries of Doc/Chem/Stk. Using this feature you can restrict the user to only make calls that he originally planned and was approved for in Daily Planning. 
          </h4>

          <div className="d-flex flex-row align-items-center justify-content-between mt-5">
            <div className="web-app__heading">Auto Approval</div>
            <PiDotsThreeCircleLight
              className="icon-color-tertiary cursor-pointer"
              onClick={() => 
              {
                setType("AutoApproval")
                setShowEdit(true)
              }
              }
            />
          </div>
          <h4 className="mt-1 recommend" >
          This feature allows the user to get automatically approved to the selected entries. WARNING: Please note the selected entries will automatically get approved for the user with any warning or notification to the Manager or Admin, please make sure you understand and then start the feature.
          </h4>

          <div className="mb-2">
            <button
              type="button"
              onClick={handleSubmit}
              className="button-blue-gradient  mt-5 m-auto"
            >
              Submit
            </button>
          </div>
          {showEdit ? <EditPopup/> : null}
        </section>
      </div>
    </div>
  );
}

export default UserControls;
