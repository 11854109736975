import { Link, useLocation } from "react-router-dom";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineDashboard,
  MdOutlineDateRange,
  MdOutlineInventory2,
  MdOutlineManageAccounts,
  MdOutlineMap,
  MdOutlineMonetizationOn,
  MdOutlineToday,
  MdCardGiftcard,
  MdAlarmOn,
  MdSupport,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { FaMoneyCheckAlt } from "react-icons/fa";
import MaleAvatar from "./../../../public/img/male-avatar.png";
import FemaleAvatar from "./../../../public/img/female-avatar.png";
import { IconParkOutlineMarketAnalysis} from "../../../../components/CostIcon";
import { RiStethoscopeFill } from "react-icons/ri";

const NavLeft = () => {
  const { pathname } = useLocation();
  const { sidenavVisible, trimSideNav} = useSelector(({ app }) => app);

  const loggedIn = useSelector(({ user }) => user);
    
   return sidenavVisible ? 
   <nav
   className="sidebar"
   style={{ display: sidenavVisible ? "flex" : "none" }}
 >
   <ul className="side-nav">
     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/dashboard") && "--active"
       }`}
     >
       <Link to="/dashboard" className="side-nav__link">
         <MdOutlineDashboard className="web-app__left-nav-icon side-nav__icon" />
         <span>Dashboard</span>
       </Link>
     </li>

     {loggedIn.user.isAdmin && (
       <li
         className={`side-nav__item side-nav__item${
           pathname.startsWith("/admin-panel") && "--active"
         }`}
       >
         <Link to="/admin-panel" className="side-nav__link">
           <MdOutlineAdminPanelSettings className="web-app__left-nav-icon side-nav__icon" />
           <span>Admin Panel</span>
         </Link>
       </li>
     )}

     {!loggedIn.user.isAdmin && (
       <li
         className={`side-nav__item side-nav__item${
           pathname.startsWith("/creation-menu") && "--active"
         }`}
       >
         <Link to="/creation-menu" className="side-nav__link">
           <MdOutlineAdminPanelSettings className="web-app__left-nav-icon side-nav__icon" />
           <span>Creation Menu</span>
         </Link>
       </li>
     )}

     {/* <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/doctor-dcr") && "--active"
       }`}
     >
       <Link to="doctor-dcr" className="side-nav__link">
         <MdOutlineMedicalServices className="web-app__left-nav-icon side-nav__icon" />
         <span>Doctor DCR</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/chemist-dcr") && "--active"
       }`}
     >
       <Link to="chemist-dcr" className="side-nav__link">
         <MdOutlineHealing className="web-app__left-nav-icon side-nav__icon" />
         <span>Chemist DCR</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/stockist-dcr") && "--active"
       }`}
     >
       <Link to="stockist-dcr" className="side-nav__link">
         <MdLocalPharmacy className="web-app__left-nav-icon side-nav__icon" />
         <span>Stockist DCR</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/extra-dcr") && "--active"
       }`}
     >
       <Link to="/extra-dcr" className="side-nav__link">
         <MdOutlineSummarize className="web-app__left-nav-icon side-nav__icon" />
         <span>Extra Activity DCR</span>
       </Link>
     </li> */}

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/tour-program") && "--active"
       }`}
     >
       <Link to="/tour-program" className="side-nav__link">
         <MdOutlineMap className="web-app__left-nav-icon side-nav__icon" />
         <span>Tour Program</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/call-planning") && "--active"
       }`}
     >
       <Link to="/call-planning" className="side-nav__link">
         <RiStethoscopeFill className="web-app__left-nav-icon side-nav__icon" />
         <span>Call Planning</span>
         <span className="new-feature__shower">new</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/expenses") && "--active"
       }`}
     >
       <Link to="/expenses" className="side-nav__link">
         <MdOutlineMonetizationOn className="web-app__left-nav-icon side-nav__icon" />
         <span>Expenses</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/e-detailing") && "--active"
       }`}
     >
       <Link to="/e-detailing" className="side-nav__link">
         <MdSupport className="web-app__left-nav-icon side-nav__icon" />
         <span>E-Detailing</span>
       </Link>
     </li>

     {/* <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/sales") && "--active"
       }`}
     >
       <Link to="/sales" className="side-nav__link">
         <FaMoneyCheckAlt className="web-app__left-nav-icon side-nav__icon" />
         <span>Sales</span>
       </Link>
     </li> */}

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/primarysales") && "--active"
       }`}
     >
       <Link to="/primarysales" className="side-nav__link">
         <FaMoneyCheckAlt className="web-app__left-nav-icon side-nav__icon" />
         <span>Primary Sales</span>
       </Link>
     </li>
     
     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/secondarysales") && "--active"
       }`}
     >
       <Link to="/secondarysales" className="side-nav__link">
         <FaMoneyCheckAlt className="web-app__left-nav-icon side-nav__icon" />
         <span>Secondary Sales</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/utilities") && "--active"
       }`}
     >
       <Link to="/utilities" className="side-nav__link">
         <MdOutlineAdminPanelSettings className="web-app__left-nav-icon side-nav__icon" />
         <span>Reports</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/attendance") && "--active"
       }`}
     >
       <Link to="/attendance" className="side-nav__link">
         <MdOutlineToday className="web-app__left-nav-icon side-nav__icon" />
         <span>Attendance</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/reminders") && "--active"
       }`}
     >
       <Link to="/reminders" className="side-nav__link">
         <MdAlarmOn className="web-app__left-nav-icon side-nav__icon" />
         <span>Reminders</span>
       </Link>
     </li>

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/leave-request") && "--active"
       }`}
     >
       <Link to="/leave-request" className="side-nav__link">
         <MdOutlineDateRange className="web-app__left-nav-icon side-nav__icon" />
         <span>Leave Request</span>
       </Link>
     </li>

     {/* <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/stock-management") && "--active"
       }`}
     >
       <Link to="/stock-management" className="side-nav__link">
         <MdOutlineMedication className="web-app__left-nav-icon side-nav__icon" />
         <span>Stock Management</span>
       </Link>
     </li> */}

     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/sample-management") && "--active"
       }`}
     >
       <Link to="/sample-management" className="side-nav__link">
         <MdOutlineInventory2 className="web-app__left-nav-icon side-nav__icon" />
         <span>Sample Management</span>
       </Link>
     </li>
     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/gift-management") && "--active"
       }`}
     >
       <Link to="/gift-management" className="side-nav__link">
         <MdCardGiftcard className="web-app__left-nav-icon side-nav__icon" />
         <span>Gift Management</span>
       </Link>
     </li>
     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/profit-analysis") && "--active"
       }`}
     >
       <Link to="/profit-analysis" className="side-nav__link">
         <IconParkOutlineMarketAnalysis className="web-app__left-nav-icon side-nav__icon" />
         <span>Profit Analysis</span>
       </Link>
     </li>
     {loggedIn?.user?.des === 101 && 
     <li
       className={`side-nav__item side-nav__item${
         pathname.startsWith("/crm") && "--active"
       }`}
     >
       <Link to="/crm" className="side-nav__link">
         <IconParkOutlineMarketAnalysis className="web-app__left-nav-icon side-nav__icon" />
         <span>CRM</span>
       </Link>
     </li> }
   </ul>

   

   <div className="profile">
     <div className="profile-pic">
       <img
         src={
           loggedIn?.user?.gender?.toLowerCase() === "male"
             ? MaleAvatar
             : FemaleAvatar
         }
         alt="user"
       />
     </div>

     <div className="profile-info">
       <h3>{`${loggedIn.user.firstName} ${loggedIn.user.lastName}`}</h3>
       <p className="mt-2">{loggedIn.user.email}</p>
     </div>

     <div className="profile-settings">
       <span href="/">
         <MdOutlineManageAccounts />
       </span>
     </div>
   </div>
 </nav>

 :
 trimSideNav ? 
 <nav
      className="sidebar-trim"
      style={{display: "flex"}}
    >
      <ul className="side-nav">
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/dashboard") && "--active"
          }`}
        >
          <Link to="/dashboard" className="side-nav__link">
            <MdOutlineDashboard className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Dashboard</span> */}
          </Link>
        </li>

        {loggedIn.user.isAdmin && (
          <li
            className={`side-nav__item side-nav__item${
              pathname.startsWith("/admin-panel") && "--active"
            }`}
          >
            <Link to="/admin-panel" className="side-nav__link">
              <MdOutlineAdminPanelSettings className="web-app__left-nav-icon side-nav__icon" />
              {/* <span>Admin Panel</span> */}
            </Link>
          </li>
        )}

        {!loggedIn.user.isAdmin && (
          <li
            className={`side-nav__item side-nav__item${
              pathname.startsWith("/creation-menu") && "--active"
            }`}
          >
            <Link to="/creation-menu" className="side-nav__link">
              <MdOutlineAdminPanelSettings className="web-app__left-nav-icon side-nav__icon" />
              {/* <span>Creation Menu</span> */}
            </Link>
          </li>
        )}

        {/* <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/doctor-dcr") && "--active"
          }`}
        >
          <Link to="doctor-dcr" className="side-nav__link">
            <MdOutlineMedicalServices className="web-app__left-nav-icon side-nav__icon" />
            <span>Doctor DCR</span>
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/chemist-dcr") && "--active"
          }`}
        >
          <Link to="chemist-dcr" className="side-nav__link">
            <MdOutlineHealing className="web-app__left-nav-icon side-nav__icon" />
            <span>Chemist DCR</span>
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/stockist-dcr") && "--active"
          }`}
        >
          <Link to="stockist-dcr" className="side-nav__link">
            <MdLocalPharmacy className="web-app__left-nav-icon side-nav__icon" />
            <span>Stockist DCR</span>
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/extra-dcr") && "--active"
          }`}
        >
          <Link to="/extra-dcr" className="side-nav__link">
            <MdOutlineSummarize className="web-app__left-nav-icon side-nav__icon" />
            <span>Extra Activity DCR</span>
          </Link>
        </li> */}

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/tour-program") && "--active"
          }`}
        >
          <Link to="/tour-program" className="side-nav__link">
            <MdOutlineMap className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Tour Program</span> */}
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/expenses") && "--active"
          }`}
        >
          <Link to="/expenses" className="side-nav__link">
            <MdOutlineMonetizationOn className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Expenses</span> */}
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/e-detailing") && "--active"
          }`}
        >
          <Link to="/e-detailing" className="side-nav__link">
            <MdSupport className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>E-Detailing</span> */}
          </Link>
        </li>

        {/* <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/sales") && "--active"
          }`}
        >
          <Link to="/sales" className="side-nav__link">
            <FaMoneyCheckAlt className="web-app__left-nav-icon side-nav__icon" />
            <span>Sales</span>
          </Link>
        </li> */}

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/primarysales") && "--active"
          }`}
        >
          <Link to="/primarysales" className="side-nav__link">
            <FaMoneyCheckAlt className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Primary Sales</span> */}
          </Link>
        </li>
        
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/secondarysales") && "--active"
          }`}
        >
          <Link to="/secondarysales" className="side-nav__link">
            <FaMoneyCheckAlt className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Secondary Sales</span> */}
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/utilities") && "--active"
          }`}
        >
          <Link to="/utilities" className="side-nav__link">
            <MdOutlineAdminPanelSettings className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Reports</span> */}
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/attendance") && "--active"
          }`}
        >
          <Link to="/attendance" className="side-nav__link">
            <MdOutlineToday className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Attendance</span> */}
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/reminders") && "--active"
          }`}
        >
          <Link to="/reminders" className="side-nav__link">
            <MdAlarmOn className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Reminders</span> */}
          </Link>
        </li>

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/leave-request") && "--active"
          }`}
        >
          <Link to="/leave-request" className="side-nav__link">
            <MdOutlineDateRange className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Leave Request</span> */}
          </Link>
        </li>

        {/* <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/stock-management") && "--active"
          }`}
        >
          <Link to="/stock-management" className="side-nav__link">
            <MdOutlineMedication className="web-app__left-nav-icon side-nav__icon" />
            <span>Stock Management</span>
          </Link>
        </li> */}

        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/sample-management") && "--active"
          }`}
        >
          <Link to="/sample-management" className="side-nav__link">
            <MdOutlineInventory2 className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Sample Management</span> */}
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/gift-management") && "--active"
          }`}
        >
          <Link to="/gift-management" className="side-nav__link">
            <MdCardGiftcard className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Gift Management</span> */}
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/profit-analysis") && "--active"
          }`}
        >
          <Link to="/profit-analysis" className="side-nav__link">
            <IconParkOutlineMarketAnalysis className="web-app__left-nav-icon side-nav__icon" />
            {/* <span>Profit Analysis</span> */}
          </Link>
        </li>
      </ul>
    </nav>
    :
    null;
  
};

export default NavLeft;
