export const title = "Approvals";

export const links = [
  {
    name: "Call Report",
    route: "approve-call-report",
    accessor: "call-report",
  },
  {
    name: "Tour Program",
    route: "approve-tp",
    accessor: "tp",
  },
  {
    name: "Call Planning",
    route: "approve-cp",
    accessor: "cp",
  },
  {
    name: "Expense",
    route: "approve-expense",
    accessor: "expense",
  },
  {
    name: "Leave Request",
    route: "approve-leave-request",
    accessor: "lr",
  },
  {
    name: "Doctors",
    route: "approve-doctors",
    accessor: "doctor",
  },
  {
    name: "Chemists",
    route: "approve-chemists",
    accessor: "chemist",
  },
  {
    name: "Stockists",
    route: "approve-stockists",
    accessor: "stockist",
  },
  {
    name: "City",
    route: "approve-city",
    accessor: "city",
  },
  {
    name: "Route",
    route: "approve-route",
    accessor: "route",
  },
  {
    name: "Secondary Sales",
    route: "approve-secondary-sale",
    accessor: "route"
  },
  {
    name: "Primary Sales",
    route: "approve-primary-sale",
    accessor: "route"
  },
  {
    name: "Samples",
    route: "samples-approve",
    accessor: "route"
  },
  {
    name: "Gifts",
    route: "gifts-approve",
    accessor: "route"
  },
  {
    name: "Geo Fencing",
    route: "approve-geo-fencing",
    accessor: "route"
  },
  {
    name: "Deletion Request",
    route: "deletion-request",
    accessor: "route"
  },
];
