import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";
import serialise from "../../../../../utils/serialiseResponse";
import { yearOptions} from "../../../../../utils/helper";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import Select from "react-select";
import { clearTarget, hqYearlyTargets } from "../../../../../reducers/reports/targetReducer";


const YearlyHqTargets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [year, setYear] = useState(yearOptions[0]);
  const yearlytargets = useSelector(({targetReducer}) => targetReducer);

  useEffect(()=>{
    dispatch(hqYearlyTargets({year : year?.label}));

    return () =>{
      dispatch(clearTarget());
    }
  },[dispatch,year]);
  
  const redirectToEdit = (userId, element) => {
    navigate(`/admin-panel/users/set-user-target/${userId}`, {
      state: element,
    });
  }; 
  
  const tableData = useMemo(() => serialise(yearlytargets?.data),[yearlytargets]);

  const columns = useMemo(
    () => [
      {
        Header: "headquarter",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 130,
        showTotal: true,
        placeholderTotal: "Total",
        wordWrap : "break-word",
        Cell : (props) => {
          return <div style={{ display: 'flex', alignItems: 'center' }}> 
            <div className="react-table-view-link"
            onClick={() =>{
              redirectToEdit(props?.row?.original?._id, props?.row?.original)}}>
          {/* { <BiTargetLock className="icon-color-green"/>   }       */}
            </div>
          {props?.row?.original?.name}
            </div>;
        },
      },
      {
        Header: "Apr",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Apr",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Apr) return props?.row?.original?.Apr;
          else return null;
        },
      },
      {
        Header: "May",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "May",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.May) return props?.row?.original?.May;
          else return null;
        },
      },
      {
        Header: "Jun",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Jun",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Jun) return props?.row?.original?.Jun;
          else return null;
        },
      },
      {
        Header: "Jul",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Jul",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Jul) return props?.row?.original?.Jul;
          else return null;
        },
      },
      {
        Header: "Aug",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Aug",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Aug) return props?.row?.original?.Aug;
          else return null;
        },
      },
      {
        Header: "Sep",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Sept",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Sept) return props?.row?.original?.Sept;
          else return null;
        },
      },
      {
        Header: "Oct",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Oct",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Oct) return props?.row?.original?.Oct;
          else return null;
        },
      },
      {
        Header: "Nov",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Nov",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Nov) return props?.row?.original?.Nov;
          else return null;
        },
      },
      {
        Header: "Dec",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Dec",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Dec) return props?.row?.original?.Dec;
          else return null;
        },
      },
      {
        Header: "Jan",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "Jan",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Jan) return props?.row?.original?.Jan;
            else return null;
          }
      },
      {
        Header: "Feb",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "Feb",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Feb) return props?.row?.original?.Feb;
          else return null;
        },
      },
      {
        Header: "Mar",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 90,
        showTotal: true,
        totalAccessor : "Mar",
        wordWrap : "break-word",
        Cell: (props)=>{
          if(props?.row?.original?.Mar) return props?.row?.original?.Mar;
          else return null;
        },
      },
      {
        Header:"Total",
        accessor:"total",
        minWidth: 40,
        maxWidth: 100,
        disableFilters: true,
        showTotal: true,
        totalAccessor : "total",
        wordWrap : "break-word"
      }
    ],
    [tableData]
  );
  
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Yearly Targets" />

        <section className="area-creation-content__info">
            <div className="primarysales-filter"
            style={{marginTop:"0", padding:"0", marginBottom:"2rem"}}>
                <div className="util-tp-filter">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Month"
                className="mt-3"
                options={yearOptions}
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
                </div>
            </div>
        <h2 className="web-app__heading mb-4 ms-2">
        Showing ({tableData  ? tableData.length : 0}) Entries
            </h2>
          <div className="filter-table">
            <Table columns={columns} data={tableData ? tableData : []} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default YearlyHqTargets;
