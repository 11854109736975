import { DESIGNATIONS } from "../constants/enums";

const serialise = (arr) => {
  
  if (!arr) return;
  let sr = 0;
  const newArr = arr.map((e) => {
    sr++;
    
    const newObj = { sr };


    for (const [key, value] of Object.entries(e)) {
     
      
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null 
       
      ) {
        
        if(key === "stockist")newObj[key] = value.businessName ;
        else if (key === "reportingManager")newObj[key] = value;
        else if (key === "authorizedBy")newObj[key] = value.fullName;
        else if (key === "deletionRequest")newObj[key] = value;
        else if (key !== "workAreas" && key !== "address" && key!=="category" && key!=="type" && key!=="product" && key !== 'submitter' && key !== "user" && key !== 'holiday' && key !== "leave") newObj[key] = value.name;
        
        else  newObj[key] = value;
      } 
      else newObj[key] = value;
    }


    return newObj;
  });
  // console.log(newArr);
  return newArr;
};

export const serialiseChemStk = (arr) => {
  if (!arr) return;
  let sr = 0;

  const newArr = arr.map((e) => {
    sr++;
    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (key !== "workAreas") newObj[key] = value.businessName;
        else newObj[key] = value;
      } else newObj[key] = value;
    }

    return newObj;
  });

  return newArr;
};

export const serialiseGift = (arr) => {
  
  if (!arr) return;
  let sr = 0;

  const newArr = arr.map((e) => {
    sr++;
    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (key !== "workAreas") newObj[key] = value.gift;
        else newObj[key] = value;
      } else newObj[key] = value;
    }

    return newObj;
  });

  return newArr;
};

export const serialiseSSG = (arr) => {
  if (!arr) return;
  let sr = 0;

  const newArr = arr.map((e) => {
    sr++;
    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (typeof key?.prod === "object") newObj[key] = value.focusProduct;
        else if (key?.docID === "docID") newObj[key] = value.name;
        else newObj[key] = value;
      } else newObj[key] = value;
    }

    return newObj;
  });

  return newArr;
};

export const generateSelectData = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({ label: name==="fullName"?`${e["firstName"]} ${e["lastName"]}`:e[name], value: e._id, ...e });
  });

  return selectData;
};

export const generateMultiSelectData = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push(e._id);
  });

  return selectData;
};

export const generateSelectRoute = (arr) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label: `${e.fromCity?.name} - ${e.toCity?.name}`,
      value: e._id,
      distance: e.distance,
      type: e.areatype,
      headquarter: e.headquarter._id,
    });
  });

  return selectData;
};

export const generateSelectUser = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label: name==="fullName"?`${e["firstName"]} ${e["lastName"]}`:e[name],
      value: e._id,
      designation: e?.des,
    });
  });

  return selectData;
};

export default serialise;

export const addIndex = (array) =>{
  
  array?.forEach(function(item, index) {
    item.sr = index+1;
  });
  return array;
 }

 export const createSetForAttendance = (array) =>{
   let set = new Set();
   
  // var getDaysArray = function(start, end) {
  //   for(let dt =new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
  //       leaves.push(new Date(dt));
  //   }
  // }; 

//  leaveData.forEach((elem)=>getDaysArray(elem.startDate,elem.endDate))


  array.forEach(function(item){
    if(item.isPresent){
      
      set.add(`present:${new Date(item.dateString).getDate()}`);
    }else if(item.isLeave){
      set.add(`leave:${new Date(item.dateString).getDate()}`);
    }else if(item.isHoliday){
      set.add(`holiday:${new Date(item.dateString).getDate()}`)
    }
  })

  return set;
 }

 export const getDesignation = (desig) =>{
  let Des = DESIGNATIONS.find(e=>e.value ===desig);
  return Des?.name;
 }