import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {
  addDivision,
  clearDivisions,
  deleteDivision,
  editDivision,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import serialise from "../../../../utils/serialiseResponse";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";

// const columnData = [{ title: "Division", field: "name" }];

const AdminDivision = () => {
  const dispatch = useDispatch();
  const divisionData = useSelector(({ division }) => division);

  const [division, setDivision] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [divData, setDivData] = useState();
  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [division, setDivision] = useState("");
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    console.log(showDelete);

    let updatedData = { ...data, name: division };
    const handleEdit = async (e) => {
      try {
        e.preventDefault();
        await dispatch(editDivision(updatedData?._id, updatedData));
        setShowEdit(false);
        customToast.success("Division Edited Successfully");
      } catch (error) {
        setShowEdit(false);
        customToast.error(error?.response?.data?.data ? `${error?.response?.data?.data}` : "Something went wrong !")
      }
    };

    const handleDelete = async (e) => {
      try {
        e.preventDefault();
        await dispatch(deleteDivision(updatedData?._id));
        setShowEdit(false);
        customToast.success("Division Deleted Successfully");
      } catch (error) {
        setShowEdit(false);
        customToast.error(error?.response?.data?.data ? `${error?.response?.data?.data}` : "Something went wrong !")
      }
    };

    useEffect(() => {
      setDivision(data.name);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Enter Division <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="division"
                  className="me-5"
                  placeholder="Enter Division"
                  value={division}
                  onChange={({ target }) => setDivision(target.value)}
                />
              </div>
            </div>

            {showDelete ? <section className="edit-popup" style={{ position: "absolute", top: "50%"}}>
                <div className="edit-content" style={{width: "40%"}}>
                  <div className="edit-popup__heading" style={{display: "flex", justifyContent: "flex-end"}}>
                    {/* <h2 className="web-app__heading">Details</h2> */}
                    <RiCloseCircleLine
                      className="edit-popup__close"
                      onClick={() => {
                        setShowDelete(false)
                        setShowEdit(false)
                      }}
                    />
                  </div>
                  <div>Are you sure want to delete <span className="text-danger"><br />
                  {/* {dependents?.totalCount}  */}
                  dependencies </span>?</div>
                  
               <div className="edit-form-button-container">
               <button
                  type="submit"
                  className="button-delete mt-4"
                  onClick={handleDelete}
                >
                  <span className="button-delete__icon-container">
                    <MdDeleteOutline className="button-delete__icon" />
                  </span>
                  <span className="button-delete__text">Delete</span>
                </button>
                <button
                  className="button-submit-green mt-4"
                  onClick={() => {
                    setShowDelete(false)
                    setShowEdit(false)
                  }}
                >
                  <span className="button-submit-green__icon-container">
                  <RiCloseCircleLine className="button-submit-green__icon" style={{fontSize: "1.5em"}}/>
                  </span>
                  <span className="button-submit-green__text">Cancel</span>
                </button>
               </div>
                </div> 
                </section> : null}

            

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4"
              onClick={handleEdit}>
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                className="button-delete mt-4"
                onClick={() => setShowDelete(true)}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  useEffect(() => {
    dispatch(setDivisions());
    return ()=> {dispatch(clearDivisions());}
  }, [dispatch]);

  const setEditForm = (data) => {
    setShowEdit(false);
    setDivision(data?.name);
    setDivData(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Division",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(divisionData), [divisionData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!division) return customToast.error("Please Enter Division Name");

    var index = tableData.findIndex(p => p.name.toLocaleLowerCase() == division.toLocaleLowerCase());
      
   
    if(index === -1){
      let error = false;
    try {
      await dispatch(addDivision({ name: division }));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) {
      customToast.success("Division Successfully Created");
      setDivision("");
    }
    }
    else{
      customToast.error("Division Already Exists");
    }

    
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Divisions" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <label htmlFor="division">
              Enter Division <span className="asterisk-imp">*</span>
            </label>
            <br />
            <input
              type="text"
              id="division"
              className="me-5"
              placeholder="Enter Division"
              value={division}
              onChange={({ target }) => setDivision(target.value)}
            />

            <button type="submit" className="button-blue-gradient mt-4">
              Add Division
            </button>
          </form>
        </section>

        <section className="area-creation-content__info">
        {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
          <div className="filter-table">
            {/* <MaterialTable
              editable={{
                onRowUpdate: (newRow, oldRow) =>
                  new Promise((resolve, reject) => {
                    dispatch(editDivision(newRow._id, newRow));
                    setTimeout(() => resolve(), 500);
                  }),
                onRowDelete: (newRow, oldRow) =>
                  new Promise((resolve, reject) => {
                    dispatch(deleteDivision(newRow._id, newRow));
                    setTimeout(() => resolve(), 500);
                  }),
              }}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={columnData}
              data={divisionData}
              title="Present Divisions"
            /> */}
            
            <Table columns={columns} data={tableData} />
          </div>

          {showEdit ? <EditPopup data={divData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminDivision;
