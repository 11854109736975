import { useEffect, useState, useMemo, useCallback } from "react";
import PageTitle from "../../../../components/PageTitle";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import customToast from "../../../../components/CustomToast";
import { RiCloseCircleLine } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import Table from "../../../../components/Table";
import serialise from "../../../../utils/serialiseResponse";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { useDispatch } from "react-redux";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import { updateTrigger } from "../../../../reducers/approveLengthReducer";
import moment from "moment-timezone";
import { approveCallPlanning, rejectCallPlanning, viewCallPlanningForApproval } from "../../../../services/callPlanning";

const ApprovalCP = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [reports, setReports] = useState([]);
  const [isApprove, setIsApprove] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [allSelected, setAllSelected] = useState(false);


  const dispatch = useDispatch();
  const SelectColumnFilter = useCallback(
    ({ column, columns }) => {
      return (
        <div className="search-filter__container" onClick={(e) => { e.stopPropagation() }}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
              <ImCheckboxChecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = false;
                });
                setAllSelected(false);
              }} />
            ) : (
              <ImCheckboxUnchecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = true;
                });
                setAllSelected(true);
              }} />
            )
            }
          </span>
          }
        </div>
      );
    }, [allSelected])
  const handleApprove = async (data) => {
    if (data.length === 0) return customToast.error("Please select a call planning report");
    const payload = { type: "bulk", ids: data };
    await approveCallPlanning(payload);
    setAllSelected(false);
    setIsApprove(!isApprove);
  };
  const handleReject = async (data) => {
    if (data.length === 0) return customToast.error("Please select a call planning report");
    const payload = { type: "bulk", ids: data };
    await rejectCallPlanning(payload);
    setIsRejected(!isRejected);
    setAllSelected(false);
  };
  const tableData = useMemo(() => serialise(reports), [reports]);
  const EditPopup = () => {
    const data = [];
    tableData.forEach((el, idx) => {
      if (el?.isSelected) {
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
        {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Do you want to approve the ${data.length} call planning reports?`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: (props) => <span>{moment(props?.row?.original?.date).format("D MMM YYYY")}</span>,
      },
      {
        Header: "Employee Name",
        accessor: "submitter.firstName",
        minWidth: 180,
        maxWidth: 180,
        Cell: (props) => (
          props?.row?.original?.submitter?.firstName + " " + props?.row?.original?.submitter?.lastName
        )
      },
      {
        Header: "Doctors",
        accessor: "doctors",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.doctors?.length ?? "-"
        }
      },
      {
        Header: "Chemists",
        accessor: "chemists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.chemists?.length ?? "-"
        }
      },
      {
        Header: "Stockists",
        accessor: "stockists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.stockists?.length ?? "-"
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          return (
            <Link
              to={"details"}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
      {
        Header: "Select",
        accessor: "none",
        Cell: (props) => {
          const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false;
                  setLocal(false);
                }} />
              ) : (
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true;
                  setLocal(true);
                }} />
              )
              }
            </span>
          );
        },
        minWidth: 60,
        maxWidth: 60,
        disableFilters: false,
        disableSortBy: true,
        Filter: SelectColumnFilter
      },
    ],
    [SelectColumnFilter, tableData]
  );//do not remove tableData even if es-lint suggests

  useEffect(() => {
    async function asyncFetch() {
      let res = await viewCallPlanningForApproval();
      setReports(res.data);
      dispatch(
        updateTrigger({ index: 0, data: res.data })
      );
    }

    asyncFetch();
  }, [isApprove, isRejected, dispatch]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Call Planning" />

        <section className="area-creation-content__info">
          <div className="d-flex justify-content-end gap-5 pe-2">
            <button
              type="submit"
              className="button-submit-green"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
              }}
            >
              <span className="button-submit-green__icon-container">
                <BsCheck2Circle className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Actions</span>
            </button>
          </div>
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalCP;
