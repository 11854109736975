import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import ProfitAnalysisBox from "../../../../components/ProfitAnalysisBox";
import PageTitle from "../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import {RiCloseCircleLine, RiEdit2Fill} from "react-icons/ri" ;
import { monthOptions, yearOptions } from "../../../../utils/helper";
import { MacroFlower } from "../../../../components/CostIcon";
import { AiFillSave } from "react-icons/ai";
import { viewChemistCost, putChemistCost } from "../../../../services/profitCenter";
import Table from "../../../../components/Table";
import { MdDelete, MdDeleteOutline } from "react-icons/md";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const ChemistCost = () => {
  const dispatch = useDispatch();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const [month, setMonth] = useState(null);
  const [employee, setEmployee] = useState([]);
  const [year, setYear] = useState(yearOptions[0]);
  const [hq, setHq] = useState(null);
  const [expense, setExpense] = useState(0);
  const [editRow, setEditRow] = useState(null);
  const [editing, setEditing] = useState(false);
  const [rowEditing, setRowEditing] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState(undefined);
  const [addedEmp, setAddedEmp] = useState([]);
  const [actions, setActions] = useState(false);
  const [billId, setBillId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [deleteRowProp, setDeleteRowProp] = useState(null);

  const upInvestmentRef = useRef(0) ;
  const upSampleQtyRef =useRef(0);
  const upSampleAmtRef = useRef(0);
  const upGiftQtyRef = useRef(0); 
  const upGiftAmtRef = useRef(0); 
  const upmisRef = useRef(0);
  const upTotalRef = useRef(0);

  const detailedVisibilityStatus = useSelector(({ analysisSetting }) => analysisSetting.detailedReport);

  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const employeeSelect = useMemo(
    () => generateSelectData(employee, "name"),
    [employee]
  );

  useEffect(() => {
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  useEffect(()=>{
    if(!month || !year || !hq)return ;

    viewChemistCost({typ:"chemOnly", month:month?.value, year:year?.label, hq:hq?.value}).then(({data})=>{
      if(data.headquarter){
        const emp = serialise(data.table);
        setEmployee(emp);
        setActions(true);
        setBillId(data._id);
        setEditing(false);
        setAddedEmp(emp);
        viewChemistCost({typ:"chemAll", month:month?.value, year:year?.label, hq:hq?.value}).then(({data})=>{
          console.log('all data ', data);
          const empSelect = serialise(data)
          console.log('emp slect ', empSelect);
          setEmployee(empSelect);
        })
      }else{
        const emp = serialise(data);
        console.log('emp ', emp);
        setEmployee(emp);
        setEditing(false);
      }
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[month, year, hq]) ;

  useEffect(()=>{
    let e = 0 ;
    for(const x of addedEmp){
      e += x?.total || 0 ;
    }
    setExpense(e);
  },[addedEmp]);

  useEffect(() => {
    setAddedEmp([]);
    setSelectedEmp(null);
    setActions(false);
  }, [month, year, hq]);

  useEffect(() => {
    let e = 0;
    for(const x of addedEmp){
      e += x?.total || 0 ;
    }
    setExpense(e);
  }, [addedEmp])
  
  const hadleAutoFill = useCallback(()=>{
    if(!month || !year || !hq)return customToast.error("Please Select Mandatory Fields");
    if(editing)return customToast.error("Sorry can't do this for previous bill");
    viewChemistCost({month:month?.value, year:year?.label, hq:hq?.value}).then(({data})=>{
      const emp = serialise(data);
      setEmployee(emp);
      
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[hq,month,year,editing]);

  const handleEditRow = useCallback((row)=>{
    if(rowEditing)return customToast.error("Please Save Your Changes");
    upInvestmentRef.current = row?.investment || 0 ;
    upSampleAmtRef.current = row?.sampleAmt || 0;
    upSampleQtyRef.current = row?.sampleQty || 0;
    upGiftQtyRef.current = row?.giftQty || 0 ;
    upGiftAmtRef.current = row?.giftAmt || 0 ;
    upmisRef.current = row?.mis || 0 ;
    upTotalRef.current = row?.total || 0;
    
    setEditRow(row?.name);
    setRowEditing(true);

  },[rowEditing]);

  const submitEditedRow = useCallback((prop)=>{
    
    const editedObj = addedEmp.find((emp, index) => {
      return emp.name === prop.name;
    })
    editedObj.total = Number(upTotalRef.current);

    setEditRow(null);
    setRowEditing(false);
  },[addedEmp]);

  const handleEditRowDetailed = useCallback((row)=>{
    if(rowEditing)return customToast.error("Please Save Your Changes");
    upInvestmentRef.current = row?.investment || 0 ;
    upSampleAmtRef.current = row?.sampleAmt || 0;
    upSampleQtyRef.current = row?.sampleQty || 0;
    upGiftQtyRef.current = row?.giftQty || 0 ;
    upGiftAmtRef.current = row?.giftAmt || 0 ;
    upmisRef.current = row?.mis || 0 ;
    
    setEditRow(row?.name);
    setRowEditing(true);

  },[rowEditing]);

  const submitEditedRowDetailed = useCallback((name)=>{
    
    const editedObj = addedEmp.find((emp, index) => {
      return emp.name === name;
    })
    editedObj.investment = Number(upInvestmentRef.current) ;
    editedObj.sampleQty = Number(upSampleQtyRef.current) ;
    editedObj.sampleAmt = Number(upSampleAmtRef.current) ;
    editedObj.giftQty = Number(upGiftQtyRef.current) ;
    editedObj.giftAmt = Number(upGiftAmtRef.current) ;
    editedObj.mis = Number(upmisRef.current);
    editedObj.total = editedObj.investment + 
      editedObj.sampleQty + 
      editedObj.sampleAmt + 
      editedObj.mis + 
      editedObj.giftQty + 
      editedObj.giftAmt ;

    setEditRow(null);
    setRowEditing(false);
  },[addedEmp]);

  const handleDelete = useCallback(()=>{
    putChemistCost({
      isDeleting:true,
      _id: billId
    }).then((data)=>{
      customToast.success("Bill Deleted Successfully");
    }).catch((err)=>{
      customToast.error("Sorry Something Went Wrong!");
    })
    setEmployee([]);
    setHq(null);
  },[billId]);

  const deleteRowFromAddedEmployee = useCallback((prop) => {
    console.log('prop came ', prop, "from ", addedEmp);
    let newAddedEmp = [];
    addedEmp.map((emp, index) => {
      if (emp.name !== prop){
        newAddedEmp = [...newAddedEmp, emp];
      }
    });
    console.log('new added emp ', newAddedEmp);
    setAddedEmp(newAddedEmp);
  },[addedEmp]);

  const submitBill = useCallback((e)=>{
    e.preventDefault();
    if(rowEditing)return customToast.info("Please Save Your Changes!");
    if (actions){
      putChemistCost({
        month:month?.value,
        year:year?.label,
        hq:hq?.value,
        payload:addedEmp,
        _id: billId,
      }).then((data)=>{
        customToast.success("Bill Saved Successfully");
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })
    } else {
      putChemistCost({
        month:month?.value,
        year:year?.label,
        hq:hq?.value,
        payload:addedEmp, 
      }).then((data)=>{
        customToast.success("Bill Saved Successfully");
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })

    }
    setEmployee([]);
    setHq(null);
  },[addedEmp,hq,month,year,editing,rowEditing]);

  const addEmployeeHandler = useCallback((e) => {
    e.preventDefault();
    console.log(month, year, hq, selectedEmp);
    if(!month || !year || !hq || !selectedEmp)return customToast.error("Please Select Mandatory Fields");
    if(editing)return customToast.error("Sorry can't do this for previous bill");
    let duplicatePresent = false;
    for (let i=0; i<addedEmp.length; i++){
      if (addedEmp.at(i).name === selectedEmp.name){
        duplicatePresent = true;
        break;
      }
    }
    if (duplicatePresent){
      return customToast.error("User already present");
    } else {
      setAddedEmp([selectedEmp, ...addedEmp]);
    }
  }, [month, year, hq, selectedEmp, addedEmp, editing]);

  const detailedColumnsEditing = useMemo(
    () => [
      {
        Header: "Chemist",
        accessor: "name",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Investment",
        accessor: "investment",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"investment",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.investment}
              onChange={(e) => {
                upInvestmentRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.investment}</span>
          );
        },
      },
      {
        Header: "Sample QTY",
        accessor: "sampleQty",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"sampleQty",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.sampleQty}
              onChange={(e) => {
                upSampleQtyRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.sampleQty}</span>
          );
        },
      },
      {
        Header: "Sample Amount",
        accessor: "sampleAmount",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"sampleAmt",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.sampleAmt}
              onChange={(e) => {
                upSampleAmtRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.sampleAmt}</span>
          );
        },
      },
      {
        Header: "Gift QTY",
        accessor: "giftQty",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"giftQty",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.giftQty}
              onChange={(e) => {
                upGiftQtyRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.giftQty}</span>
          );
        },
      },
      {
        Header: "Gift Amount",
        accessor: "giftAmount",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"giftAmt",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.giftAmt}
              onChange={(e) => {
                upGiftAmtRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.giftAmt}</span>
          );
        },
      },
      {
        Header: "Miscellaneous",
        accessor: "miscellaneous",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"mis",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.mis}
              onChange={(e) => {
                upmisRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.mis}</span>
          );
        },
      },
      {
        Header: "Total",
        accessor: "total",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"total"
      },
      {
        Header: "Actions",
        accessor: "actions",
        minWidth: 120,
        maxWidth: 120,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <AiFillSave type="button" className="icon-color-green mx-3" size={24} onClick={(e) => {
                  e.preventDefault();
                  submitEditedRowDetailed(props?.row?.original?.name);
                }}/>
              </span>
            </div>
          ) : (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <RiEdit2Fill type="button" className="icon-color-green mx-3" size={25} onClick={(e) => {
                  e.preventDefault();
                  handleEditRowDetailed(props?.row?.original);
                }}/>
              </span>
              <span className="react-table-view-link mx-3" >
                <MdDelete type="button" className="button-delete__icon" size={25} onClick={(e) => {
                  e.preventDefault();
                  setDeleteRowProp(props?.row?.original?.name);
                  setShowDelete(true);
                }} />
              </span>
            </div>
          );
        }
      },
    ],
    [editRow, handleEditRowDetailed, submitEditedRowDetailed, deleteRowFromAddedEmployee]
  );

  const columnsEditing = useMemo(
    () => [
      {
        Header: "Chemist",
        accessor: "name",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Total",
        accessor: "total",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"total",
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.total}
              onChange={(e) => {
                upTotalRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.total}</span>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        minWidth: 120,
        maxWidth: 120,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const editable = props?.row?.original?.name === editRow;
          return editable ? (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <AiFillSave type="button" className="icon-color-green mx-3" size={24} onClick={(e) => {
                  e.preventDefault();
                  submitEditedRow(props?.row?.original);
                }}/>
              </span>
            </div>
          ) : (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <RiEdit2Fill type="button" className="icon-color-green mx-3" size={25} onClick={(e) => {
                  e.preventDefault();
                  handleEditRow(props?.row?.original);
                }}/>
              </span>
              <span className="react-table-view-link mx-3" >
                <MdDelete type="button" className="button-delete__icon" size={25} onClick={(e) => {
                  e.preventDefault();
                  setDeleteRowProp(props?.row?.original?.name);
                  setShowDelete(true);
                }} />
              </span>
            </div>
          );
        }
      },
    ],
    [editRow, handleEditRow, submitEditedRow]
  );

  const EditPopup = ({ type }) => {

    return (
      <section className="edit-popup" style={{ zIndex: 100 }}>
        <div className="edit-content">

          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete All</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowDelete(false)}
            />
          </div>

          <div className="mt-4">
            <p>Are you sure you want to delete the entry of {deleteRowProp} from the month of {month.label}? </p>
          </div>

          <button
            type="submit"
            className="button-delete mt-4"
            onClick={() => {
              deleteRowFromAddedEmployee(deleteRowProp)
              setShowDelete(false)
            }}
          >
            <span className="button-delete__icon-container">
              <MdDeleteOutline className="button-delete__icon" />
            </span>
            <span className="button-delete__text">Delete</span>
          </button>
          
        </div>
      </section>
    );
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">

        {
          showDelete ?
          <EditPopup /> :
          null
        }

        <PageTitle title="Chemist" previousUrl="/profit-analysis" isDelete={editing ? true : false} toggle={handleDelete}/>
        <section className="area-creation-content__form">
          <form >
            <div className="d-flex gap-5 w-100">
              <div className="d-flex flex-wrap gap-5 w-75">
                <div className="util-tp-filter">
                  <p>
                    Select Month <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Month"
                    className="mt-3"
                    options={monthOptions}
                    value={month}
                    onChange={(e) => setMonth({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Year <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={year}
                    onChange={(e) => setYear({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Headquarter <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select HQ"
                    className="mt-3"
                    options={headquarterSelect}
                    value={hq}
                    onChange={(e) => setHq({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Chemist <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Chemist"
                    className="mt-3"
                    // options should have list of employees
                    options={employeeSelect}
                    value={selectedEmp}
                    onChange={(e) => setSelectedEmp({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <div className="mb-2">
                    <button type="button" className="button-blue-gradient mt-5" onClick={addEmployeeHandler}>
                      Add Chemist
                    </button>
                  </div>
                </div>
                {
                  detailedVisibilityStatus ?
                  <div className="util-tp-filter">
                    <div className="mb-2">
                      <button
                        type="button"
                        onClick={hadleAutoFill}
                        className="button-blue-gradient  mt-5"
                      >
                        <MacroFlower className="me-2"/>
                        Auto Fill
                      </button>
                    </div>
                  </div> :
                  null
                }
              </div>
              {
                detailedVisibilityStatus ?
                <ProfitAnalysisBox row1={`${addedEmp.length} CHEMISTS`} 
                  row2={`Period: ${month?.label} ${year?.label} | ${new Date(year?.label, Number(month?.value)+1, 0).getDate()} Days`} 
                  row3={`Rs ${expense}`} row4="TOTAL COST" 
                /> :
                <ProfitAnalysisBox row1={`${addedEmp.length} CHEMISTS`} 
                  row2={`Period: ${month?.label ? month?.label : ""} ${year?.label} ${new Date(year?.label, Number(month?.value)+1, 0).getDate() ? " | "+new Date(year?.label, Number(month?.value)+1, 0).getDate()+" Days" : ""}`}
                  row3={`Rs ${expense}`} row4="TOTAL COST" 
                />
              }
            </div>
          </form>
        </section>
      </div>
      <div className="filter-table pe-4">
        {
          detailedVisibilityStatus ?
          <Table columns={detailedColumnsEditing} data={addedEmp} /> :
          <Table columns={columnsEditing} data={addedEmp} />
        }
      <button type="button" className="button-submit" onClick={submitBill}>
        {actions ? "Over Ride" : "Submit" }
      </button>
      </div>
    </main>
  );
};

export default ChemistCost;
