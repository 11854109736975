import { Outlet, useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';

const CallPlanning = () => {
  const navigate = useNavigate();
    return (
      <main className="main-content">
      <div className="expense" >
          <div className="row">
              <h3 className="web-app__heading cursor-pointer"> Call Planning</h3>
              <Outlet/>
          </div>
      </div>
  </main>
      );
}

export default CallPlanning
