import React, { useState, useMemo, useEffect, useRef } from "react";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { viewProducts, viewProductV1 } from "../../../../services/products";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";
import { createEDetailingProduct } from "../../../../services/eDetailing";
import { fileToBase64 } from "../../../../utils/helper";

const UploadImages = () => {
  
  const fileInput = useRef();

  const [prod, setProd] = useState([]);
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);

  let changeHandler = (e) => {
    const imageMimeType = /image\/(png|jpg|jpeg|jfif|webp|avif)/i;
  const validFiles = Array.from(e.target.files);
  setImages([]);
  
  const filteredFiles = validFiles.filter(file => file.type.match(imageMimeType));

  if (filteredFiles.length !== validFiles.length) {
    customToast.error("Only png, jpg, jfif, webp, avif images are allowed");
    e.target.value = "";
    return;
  }

  Promise.all(filteredFiles.map(fileToBase64))
    .then((base64Images) => {
      setImages(base64Images);
    })
    .catch((error) => {
      customToast.error('Error adding images');
    });
  };

  let submitHandler = async (e) => {
    e.preventDefault();

    if(loading)return customToast.error("Please wait uploading images") ;
    setLoading(true);
    const formData = new FormData();
    formData.append('productId', product._id);
    for (let i = 0; i < images.length; i++) {
      formData.append('files', images[i]);
    }

    try {
      let data = await createEDetailingProduct(formData);
      customToast.success("Uploaded Successfully");

    } catch (err) {
      customToast.error("An unexpected error occured");
    }finally{
      setImages([]);
      fileInput.current.value = "";
      setLoading(false);
    }
  };
  useEffect(() => {
    viewProductV1("").then((response) => {
      setProd(response?.data);
    });
  }, []);

  const productsSelect = useMemo(
    () => generateSelectData(prod, "name"),
    [prod]
  );
  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle title="E-Detailing" />
        <section className="area-creation-content__info">
          <form onSubmit={submitHandler}>
            <div className="row d-flex justify-content-between align-items-center">
              <div className="d-flex w-75">
                <div className="w-25 me-5">
                  <label htmlFor="hq">Select Product</label>
                  <br />
                  <Select
                    name="product"
                    id="product"
                    styles={adminStyles}
                    value={product}
                    options={productsSelect}
                    onChange={(e) => {
                      setProduct(e);
                    }}
                    placeholder="Select Product"
                  />
                </div>

                <div className="w-75">
                  <label htmlFor="doctorCode">Select Images</label>
                  <br />
                  <input
                    onChange={changeHandler}
                    ref={fileInput}
                    type="file"
                    className=""
                    id="images"
                    placeholder="Select Images"
                    name="images"
                    multiple
                  />
                </div>
              </div>
              <button type="submit" className="button-blue-gradient mt-5">
                UPLOAD
              </button>
            </div>

          </form>
        </section>
      </div>
    </main>
  );
};

export default UploadImages;
