import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { formatDate } from "../../../../../utils/helper";
import { useEffect, useState } from "react";
import { getStockistDetails } from "../../../../../services/doctor";
import customToast from "../../../../../components/CustomToast";
import { RiCloseCircleLine } from "react-icons/ri";
import { Accordion } from "react-bootstrap";

const AdminViewDCSDetailsStockist = () => {
  const location = useLocation();

  const [stockist, setStockist] = useState();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getStockistDetails(location?.state?._id)
    .then(({data}) => {
      setStockist(data);
      // customToast.success("Stockist data fetched successfully");
    })
    .catch(err => customToast.success("Somthing went wrong!"))
  }, [location]);

  const AddtionalDetails = ({ data }) => {
  
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Additional Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowPopup(false)}
            />
          </div>

          <form>
            <div className="edit-form">

            <section className="">
              <div className="util-inner-info-box d-flex justify-content-between">
              <div>
                <h3>Created At</h3>
                <p>{formatDate(stockist?.createdAt)}</p>
                </div>
              </div>
            </section>
              <div className="user-detail-box">
                {/* <h4>Visible To</h4> */}
                <Accordion className="mt-4" alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h4>Visible To</h4></Accordion.Header>
                  <Accordion.Body className="custom-scrollbar custom-scrollable" style={{backgroundColor: `var(--color-primary-dark)`, maxHeight: '30vh'}}>
                    {stockist?.accessStockist?.map((e, i) => (
                      <div key={i} className="acc-body-card mt-2">
                        <p className="d-flex align-items-center px-4">{e.firstName} {e.lastName}</p>
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
                {/* {
                  location?.state?.accessDoctor?.map((item, idx) => (
                    <p key={idx}>{item.firstName} {item.lastName}</p>
                  ))
                } */}
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`Stockist Details`} hierarchy={true} isMoreInfo={true} toggle={setShowPopup}/>
      </div>

      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{stockist?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Business Name</h4>
          <p>{stockist?.businessName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Headquarter</h4>
          <p>{stockist?.city?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{stockist?.workingArea?.name}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Certification</h4>
          <p>{stockist?.certification}</p>
        </div>

        <div className="user-detail-box">
          <h4>Stockist Contact</h4>
          <p>{stockist?.stockistContact}</p>
        </div>

        <div className="user-detail-box">
          <h4>E-mail</h4>
          <p>{stockist?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Establishment Date</h4>
          <p>{formatDate(stockist?.establishmentDate)}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Drug Expiry Number</h4>
          <p>{stockist?.drugExpiryNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>Drug License Number</h4>
          <p>{stockist?.drugLicenseNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>GST Number</h4>
          <p>{stockist?.gstNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>Address</h4>
          <p>{stockist?.address}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>Extra Info</h4>
          <p>{stockist?.extraInfo}</p>
        </div>
      </section>

      {showPopup && <AddtionalDetails data={stockist} />}
    </div>
  );
};

export default AdminViewDCSDetailsStockist;
