import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import * as XLSX from "xlsx";
import ProductListFormat from "./../../../../assets/ProductListFormat.xlsx";
import {
  clearProductCategories,
  setProductCategories,
} from "../../../../reducers/products/categoryReducer";
import {
  clearProductTypes,
  setProductTypes,
} from "../../../../reducers/products/typeReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { uploadProductRange } from "../../../../reducers/products/rangeReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";

const AdminProdUpload = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  // const category = useSelector(({ productCategory }) => productCategory);
  // const divisionData = useSelector(({ division }) => division);
  const inputRef = useRef(null); // for excel file input

  const types = useSelector(({ productType }) => productType);

  // const [prodType, setProdType] = useState();
  // const [prodCategory, setProdCategory] = useState();
  const [uploadData, setUploadData] = useState([]);
  // const [prodDivision, setProdDivision] = useState(null);

  // const categorySelect = useMemo(
  //   () => generateSelectData(category.data, "name"),
  //   [category]
  // );
  // const typeSelect = useMemo(
  //   () => generateSelectData(types.data, "name"),
  //   [types]
  // );
  // const divisionSelect = useMemo(
  //   () => generateSelectData(divisionData, "name"),
  //   [divisionData]
  // );
  // useEffect(() => {
  //   dispatch(setProductCategories());
  //   dispatch(setProductTypes());
  //   dispatch(setDivisions());

  //   return () => {
  //     dispatch(clearDivisions());

  //     dispatch(clearProductCategories());
  //     dispatch(clearProductTypes());
  //   };
  // }, [dispatch]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const wsName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((d) => {
      setUploadData(d);
    });
  };

  const resetForm = () => {
    setUploadData([]);
  };
  const exportEXCEL = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Unsuccessful.xlsx");
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (uploadData.length === 0)
      return customToast.error("Please select a filled excel file");
    // let finalData = [];

    // uploadData.forEach((el) => {
    //   finalData.push({
    //     ...el,
    //     type: prodType?.value,
    //     division: prodDivision?.value,
    //     category: prodCategory?.value,
    //     isDeleted: false,
    //     submitter: loggedIn?.jwt?.id,
    //   });
    // });
    
    dispatch(uploadProductRange(uploadData)).then((data)=>{
      exportEXCEL(data?.data);
      customToast.success("Please open the excel file just downloaded");
    }).catch((err)=>{
      customToast.success("Encountered an error Please try again");
    });
    inputRef.current.value = null; // resetting the excel input field
    resetForm();
  };


  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Upload Product" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="employee">Upload Excel</label>
                <span className="asterisk-imp">*</span>

                <br />
                <input
                  ref={inputRef}
                  type="file"
                  placeholder="Upload File"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-3">
                <div className=" area-creation-content__form-submit mt-5">
                  <button type="submit" className="button-blue-gradient">
                    Upload List
                  </button>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-3">
                <div className="area-creation-content__form-submit mt-5">
                  <a
                    href={ProductListFormat}
                    download="ProductListFormat.xlsx"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <button type="button" className="button-blue-gradient">
                      Download Format
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="d-flex flex-wrap mt-5 justify-content-between">
              <div className=" area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Upload List
                </button>
              </div>
              <div className="area-creation-content__form-submit">
                <a
                  href={ProductListFormat}
                  download="ProductListFormat"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button type="button" className="button-blue-gradient">
                    Download Format
                  </button>
                </a>
              </div>
            </div> */}
          </form>
        </section>
      </div>
    </div>
  );
};

export default AdminProdUpload;
