
import { useState, useEffect, useMemo, useCallback, Suspense, lazy } from "react";
import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import serialise, {
//   generateSelectData,
  generateSelectUser,
} from "../../../../utils/serialiseResponse.js";
import { viewMonthlyPunchingReports, viewUsersForUtilities } from "../../../../services/utilities.js";
import {
  formatDate,
  items,
  monthOptions,
  yearOptions,
} from "../../../../utils/helper.js";
import { viewReminderCall } from "../../../../services/reports.js";
import Select from "react-select";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles.js";
import { useSelector } from "react-redux";
import Table from "../../../../components/Table/index.jsx";
import { FaEye } from "react-icons/fa";
import moment from "moment-timezone";
import customToast from "../../../../components/CustomToast/index.js";
const CascadingMenuDropDown = lazy(() => import('../../CascadingMenuDropDown'));

const UtilitiesMonthlyPunchingReport = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  // const loggedInUser = {
  //   label: `${loggedIn?.user?.firstName} ${loggedIn?.user?.lastName}`,
  //   value: loggedIn?.user?._id,
  //   designation: loggedIn?.user?.des,
  // }
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  let [punchingReports, setPunchingReports] = useState([]);
  // const [month, setMonth] = useState(monthOptions[new Date().getUTCMonth()]);
  const [month, setMonth] = useState();
  // const [year, setYear] = useState(yearOptions[0]);
  const [year, setYear] = useState();
//   const [employeeDataForExcel, setEmployeeDataForExcel] = useState()
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
//   const employeeData = useMemo(
//     () => generateSelectData(employees, "fullName"),
//     [employees]
//   );

const handleMenuClick1 = ({ keyPath }) => {
  // console.log(keyPath)
  // Assuming keyPath is ['0-2024']
  const [monthYearString] = keyPath;
  // Splitting '0-2024' into month and year
  const [month, year] = monthYearString.split('-');
  setMonth(`${month}`);
  setYear(`${year}`);
};

  const columns = useMemo(
    () => [
      
      {
        Header: "Date",
        accessor: "date",
        // disableFilters: true,
        disableSortBy: true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Check-In",
        accessor: "checkInCount",
        disableFilters: true,
        disableSortBy: true,
        totalAccessor:"doctors"
      },
      {
        Header: "Check-Out",
        accessor: "checkOutCount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          props.row.original.selectedUser = selectedUser && selectedUser
          return (
            <Link
              to="details"
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 150,
        maxWidth: 150,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser, loggedIn]
  ); 

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!month || !year) {
        customToast.error("Please select month and year");
        return;
      }

      if(!selectedUser && loggedIn?.user?.des < 121){
        customToast.error("Please select user");
        return;
      }

      const filters = {
        selectedUser: selectedUser?.value,
        // year : Number(year?.label),
        year : Number(year),
        // month : Number(month?.value)
        month : Number(month)
      };
      const punchingReportsResponse =  await viewMonthlyPunchingReports(filters)

      setPunchingReports(punchingReportsResponse.data);

    },
    [month, year, selectedUser]
  );

//   useEffect(() => {
//     try {
//       let tempdata = employeeData.filter((empData) => {
//         if (empData.label === selectedUser.label) return true;
//       })
//       setEmployeeDataForExcel(tempdata[0]);
//     } catch (error) {
//       console.log(error);
//     }
//   }, [selectedUser]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const tableData = useMemo(() => serialise(punchingReports), [punchingReports]);

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            CheckIn / CheckOut Reports
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex justify-content-between">
            
            <div className="d-flex">
            <div className="util-tp-filter me-5">
                <p className="mb-3">Select Month</p>
                <Suspense>
                  <CascadingMenuDropDown items={items}
                    title="Select Month"
                    month={month}
                    year={year}
                    onClick={handleMenuClick1}
                  />
                </Suspense>
              </div>
              {/* <div className="util-tp-filter me-5">
                <p>Select Month</p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                />
              </div> */}

              {/* <div className="util-tp-filter">
                <p>Select Year</p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div> */}
            </div>
            <div className="d-flex">
              {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.designation?.priority > 1 ||
              loggedIn?.user?.isAdmin ? (
                <div className="util-tp-filter me-5">
                  <p className="util-select-user__label">Select User</p>
                  <div className="util-select-user">
                    <MdOutlineGroup className="util-select-user__icon" />
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className="mt-3"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser({ ...e })}
                    />
                  </div>
                </div>
              ) : null}

              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  See Punching Reports
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="filter-table">
          {punchingReports?.length > 0 && (
            <h2 className="web-app__heading mb-4">
              Showing ({punchingReports?.length}) Entries
            </h2>
          )}
          {tableData?.length > 0 ? (
            <Table defaultPageSize={50} columns={columns} data={tableData} 
            // empl={selectedUser ? employeeDataForExcel : loggedIn?.user} 
            // fileSrc="Reminder Call Report" 
            // month={month} 
            />
          ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default UtilitiesMonthlyPunchingReport;
