import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom';
import Table from '../../../../components/Table';
import serialise from '../../../../utils/serialiseResponse';

const CPDetails = () => {

  const location = useLocation();

  const transformedData = (data) => {
    const expandedData = [];
    const { doctors, chemists, stockists } = data;

    // Add rows for doctors
    doctors.forEach((doc) => {
      expandedData.push({
        ...doc,
        type: "Doctor",
        name: doc?.doctor?.name || "",
      });
    });

    // Add rows for chemists
    chemists.forEach((chem) => {
      expandedData.push({
        ...chem,
        type: "Chemist",
        name: chem?.chemist?.businessName || "",
      });
    });

    // Add rows for stockists
    stockists.forEach((stk) => {
      expandedData.push({
        ...stk,
        type: "Stockist",
        name: stk?.stockist?.businessName || "",
      });
    });

    return expandedData;
  };


  const cpData = transformedData(location?.state)

  const tableData = useMemo(() => serialise(cpData), [cpData]);


  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Gifts",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => props?.row?.original?.gifts?.length ? (props?.row?.original?.gifts.map((e, i) => (
          <span key={i}>
            {e.gift} <br />
          </span>
        )))
          : ("-")
      },
      {
        Header: "Samples",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => props?.row?.original?.products?.length ? (props?.row?.original?.products.map((e, i) => (
          <span key={i}>
            {e.name} <br />
          </span>
        )))
          : ("-")
      },
    ],
    []
  )

  return (

    <form>
      <div className="filter-table">

        <Table defaultPageSize={30} columns={columns}
          data={tableData}
        />
      </div>
    </form>
  )
}

export default CPDetails
