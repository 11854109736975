import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/product/`;
const baseUrlV1 = `${apiURL}/client/v1/product/`;

export const viewProducts = async (type, details) => {
  const { data } = await axios.get(baseUrl + type, {
    params: details,
    headers: setAuth(),
  });
  
  return data;
};
export const viewProduct = async (id) => { // this is new mongoose api
  const { data } = await axios.get(baseUrlV1 + "prod-details/" + id, {
    headers: setAuth(),
  });
  
  return data;
};

export const viewProductV1 = async (details) => {
  const { data } = await axios.get(baseUrlV1, {
    params: details,
    headers: setAuth(),
  })

  return data;
}

export const viewProductType = async (details) => {
  const { data } = await axios.get(baseUrlV1 + "type", {
    params: details,
    headers: setAuth(),
  });
  
  return data;
};

export const viewProductCategory = async () => {
  const { data } = await axios.get(baseUrlV1 + "category", {
    headers: setAuth(),
  });
  
  return data;
};
export const viewDeletedProducts = async (details) => {
  const { data } = await axios.get(`${baseUrlV1}view-deleted-product`, {
    params: details,
    headers: setAuth(),
  });
  return data;
};

export const restoreDeltedProduct = async (id) => {
  const { data } = await axios.put(`${baseUrlV1}update-deleted-product/${id}`, {id: id} , {
    headers: setAuth(),
  });
  return data;
};


export const newProduct = async (type, details) => {
  const { data } = await axios.post(baseUrl + type, details, {
    headers: setAuth(),
  });

  return data;
};

export const newProductV1 = async (details) => {
  const { data } = await axios.post(baseUrlV1, details, {
    headers: setAuth(),
  });

  return data;
};

export const newProductCategory = async (details) => {
  const { data } = await axios.post(baseUrlV1 + "category", details, {
    headers: setAuth(),
  });

  return data;
};

export const newProductType = async (details) => {
  const { data } = await axios.post(baseUrlV1 + "type", details, {
    headers: setAuth(),
  })

  return data;
}

export const uploadProduct = async (details) => {
  const { data } = await axios.post(baseUrl + "upload", details, {
    headers: setAuth(),
  });

  return data;
};

export const uploadProductV1 = async (details) => {
  const { data } = await axios.post(baseUrlV1 + "upload", details, {
    headers: setAuth(),
  })
  return data;
}

export const newGift = async (details) => {
  // const { data } = await axios.post(baseUrl + "gift", details, {
  const { data } = await axios.post(baseUrlV1 + "gift", details, {
    headers: setAuth(),
  });

  
  return data;
};

export const viewGifts = async () => {
  // const { data } = await axios.get(baseUrl + "gift", {
  const { data } = await axios.get(baseUrlV1 + "gift", {
    headers: setAuth(),
  });

  return data;
};

export const editGiftDetails = async (newProduct) => {
  const { data } = await axios.put(baseUrlV1 + "gift", newProduct, {
    headers: setAuth(),
  });
  return data;
};

export const deleteGiftDetails = async (details) => {
  await axios.put(baseUrlV1 + `gift/${details}`, details,
    {
      headers: setAuth(),
    }
  );
};

export const newSample = async (details) => {
  const { data } = await axios.post(baseUrl + "sample", details, {
    headers: setAuth(),
  });

  return data;
};

export const viewSample = async (details) => {
  const { data } = await axios.get(baseUrl + "sample", {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const editProduct = async (type, id, newProduct) => {
  const { data } = await axios.put(baseUrl + `${type}/${id}`, newProduct, {
    headers: setAuth(),
  });
  return data;
};

export const updateProductRange = async (id, newProduct) => {
  const { data } = await axios.put(baseUrlV1 + `range/${id}`, newProduct, {
    headers: setAuth(),
  });
  return data;
}

export const editProductCategory = async (id, newProduct) => {
  const { data } = await axios.put(baseUrlV1 + `category/${id}`, newProduct, {
    headers: setAuth(),
  });
  return data;
};

export const updateProductType = async (id, details) => {
  const { data } = await axios.put(baseUrlV1 + `type/${id}`, details, {
    headers: setAuth(),
  })
  return data;
}

export const deleteProduct = async (type, id, details) => {
  await axios.post(baseUrl + `${type}/${id}`,details,
    {
      headers: setAuth(),
    }
  );
};

export const deleteProductRangeV1 = async (id, details) => {
  await axios.post(baseUrlV1 + `range/${id}`, details, {
    headers: setAuth(),
  });
}

export const deleteProductCategory = async (id) => {
  await axios.post(baseUrlV1 + "category/del", {
    id: id
  },
  {
    headers: setAuth(),
  });
}

export const deleteProductsType = async (id) => {
  await axios.post(baseUrlV1 + "type/del", {
    id: id
  },
  {
    headers: setAuth(),
  });
}

export const addSupplier = async (details) => {
  
    const { data } = await axios.post(baseUrl + "inventory/add/supplier", details, {
      headers: setAuth(),
    });
    return data;
  
};

export const addSupplierV1 = async (details) => {
  
    const { data } = await axios.post(baseUrlV1 + "inventory/supplier", details, {
      headers: setAuth(),
    });
    return data;
  
};

export const viewSupplier = async () => {
  
  const { data } = await axios.get(baseUrl + "inventory/view/supplier", {
    headers: setAuth(),
  });

  return data;

};

export const viewSupplierV1 = async () => {

  const { data } = await axios.get(baseUrlV1 + "inventory/supplier", {
    headers: setAuth(),
  });

  return data;

}

export const editSupplier = async (id,details) => {
  
  const { data } = await axios.post(baseUrl + "inventory/edit/supplier/"+id, details, {
    headers: setAuth(),
  });

  return data;

};

export const editSupplierV1 = async (id,details) => {
  
  const { data } = await axios.put(baseUrlV1 + "inventory/supplier/"+id, details, {
    headers: setAuth(),
  });

  return data;

};

export const deleteSupplier = async (id) => {
  
  const { data } = await axios.delete(baseUrl + "inventory/delete/supplier/"+id, {
    headers: setAuth(),
  });

  return data;

};

export const deleteSupplierV1 = async (id) => {
  
  const { data } = await axios.delete(baseUrlV1 + "inventory/supplier/"+id, {
    headers: setAuth(),
  });

  return data;

};

export const addInventory = async (details) => {
  const {data} = await axios.post(baseUrl + "inventory/add/inventory", details, {
    headers: setAuth(),
  })

  return data;
}

export const addInventoryV1 = async (details) => {
  const {data} = await axios.post(baseUrlV1 + "inventory", details, {
    headers: setAuth(),
  })
}

export const editInventory = async (details) => {
  const {data} = await axios.post(baseUrl + "inventory/edit/inventory", details, {
    headers: setAuth(),
  })
  return data;
}
export const editInventoryV1 = async (details) => {
  const {data} = await axios.put(baseUrlV1 + "inventory", details, {
    headers: setAuth(),
  })
  return data;
}
export const deleteInventory = async (details) => {
  const {data} = await axios.post(baseUrl + "inventory/delete/inventory", details, {
    headers: setAuth(),
  })
  return data;
}
export const deleteInventoryV1 = async (details) => {
  const {data} = await axios.post(baseUrlV1 + "inventory/delete", details, {
    headers: setAuth(),
  })
  return data;
}
export const viewDeletedInventory = async () => {
  const {data} = await axios.get(baseUrl + "inventory/view-deleted-inventory", {
    headers: setAuth(),
  })
  return data;
}

export const viewInventory = async (details) => {
  const {data} = await axios.get(baseUrl + "inventory/view/inventory", {
    params:details,
    headers: setAuth(),
  })

  return data;
}

export const viewInventoryV1 = async (details) => {
  const {data} = await axios.get(baseUrlV1 + "inventory", {
    params:details,
    headers: setAuth(),
  })

  return data;
}
export const updateMinInventory = async(type,payload)=>{
  // const {data} = await axios.post(baseUrl + "inventory/update/" + type, payload ,{
  const { data } = await axios.post(baseUrlV1 + "inventory/update/" + type, payload, {
    headers:setAuth(),
  })
  return data ;
}

export const updateMinInventoryV1 = async (type, payload) => {
  const { data } = await axios.post(baseUrlV1 + "inventory/update/" + type, payload, {
    headers: setAuth(),
  })
  return data;
}

export const inventoryAlerts = async()=>{
  const {data} = await axios.get(baseUrl + "inventory/minstock/alerts" , {
    headers:setAuth(),
  })
  return data ;
}

export const inventoryAlertsV1 = async () => {
  const { data } = await axios.get(baseUrlV1 + "inventory/minstock-alerts", {
    headers: setAuth(),
  })
  return data;
}