import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
// import { toggleCustomCostSetting, toggleProfitAnalysisReportSetting } from "../../../../reducers/analysisSetting";
import { Link } from "react-router-dom";
import { toggleCustomCostSetting, toggleProfitAnalysisReportSetting } from "../../../../reducers/settings";

const ProfitAnalysisSettings = () => {

  const dispatch = useDispatch();

  // const detailedStatus = useSelector(({ analysisSetting }) => analysisSetting.detailedReport);
  // const customCostStatus = useSelector(({ analysisSetting }) => analysisSetting.customCost);


  const detailedStatus = useSelector((state) => state.settings["detailed-analysis-report-preference"]);
  const customCostStatus = useSelector((state) => state.settings["custom-cost-preference"]);
  console.log(detailedStatus)

  // const detailedStatusHandler = (e) => {
  //   dispatch(toggleProfitAnalysisReportSetting("detailed-report-preferences", e.target.checked))
  // };

  const detailedStatusHandler = (e) => {
     if (e.target.checked === true) {
          dispatch(toggleProfitAnalysisReportSetting("detailed-analysis-report-preference", { value: "true" }));
        } else {
          dispatch(toggleProfitAnalysisReportSetting("detailed-analysis-report-preference", { value: "false" }));
        }
  };

  // const customCostHandler = (e) => {
  //   dispatch(toggleCustomCostSetting("custom-cost-preferences", e.target.checked))
  // }

  const customCostHandler = (e) => {
    if (e.target.checked === true) {
      dispatch(toggleCustomCostSetting("custom-cost-preference", { value: "true" }));
    } else {
      dispatch(toggleCustomCostSetting("custom-cost-preference", { value: "false" }));
    }
  }

  return (
    <main className="main-content admin-content">
      <div className="w-100 user-detail-container p-0">
        <PageTitle title="Setting" hierarchy={true}/>
      </div>

      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between"> 
        <div>
          <h1 className="web-app__heading">Detailed Report</h1>
          <h4 className="mt-4 w-75 lh-base text-info">
            This will allow you to fill investment made on employee / doctor / stockist / chemist, in a detailed format.
          </h4>
        </div>
        <div className="util-tp-filter">
          <div className="form__control pt-4" style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={ detailedStatus === "true" ? true : false }
                onClick={detailedStatusHandler}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">Custom cost</h1>
          <h4 className="mt-4 w-75 lh-base text-info">
            This will allow admins to set more than one custom costs for a product.
          </h4>
          <h4 className="mt-3 w-75 lh-base">
            {
              customCostStatus ? (
                <div className="text-primary text-info">
                  By default the number of visits is 3
                  <Link
                    className="ps-3 text-danger"
                    to='./custom-cost'
                  >Edit Here</Link>
                </div>
              ) : null
            }
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={ customCostStatus === "true" ? true : false }
                onClick={customCostHandler}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProfitAnalysisSettings;
