import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import ProfitAnalysisBox from "../../../../components/ProfitAnalysisBox";
import PageTitle from "../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import {RiCloseCircleLine, RiDeleteBackFill, RiDeleteBin2Fill, RiDeleteBin3Fill, RiDeleteBin7Fill, RiEdit2Fill} from "react-icons/ri" ;
import { monthOptions, yearOptions } from "../../../../utils/helper";
import { MacroFlower } from "../../../../components/CostIcon";
import { AiFillSave } from "react-icons/ai";
import { viewEmployees, putEmployeesCost, putMiscellaneousCost, viewMiscellaneousCost } from "../../../../services/profitCenter";
import Table from "../../../../components/Table";
import { MdDelete, MdDeleteOutline } from "react-icons/md";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const MiscellaneousCost = () => {
  const dispatch = useDispatch();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const [month, setMonth] = useState(null);
  const [employee, setEmployee] = useState([]);
  const [year, setYear] = useState(yearOptions[0]);
  const [hq, setHq] = useState(null);
  const [expense, setExpense] = useState(0);
  const [editRow, setEditRow] = useState(null);
  const [editing, setEditing] = useState(false);
  const [rowEditing, setRowEditing] = useState(false);
  const [addedEmp, setAddedEmp] = useState([]);
  const [billId, setBillId] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [deleteRowProp, setDeleteRowProp] = useState(null);
  const [misNote, setMisNote] = useState("");
  const [actions, setActions] = useState(false);

  const upNoteRef = useRef('');
  const upCostRef = useRef(0);

  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  useEffect(() => {
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  useEffect(()=>{
    let e = 0 ;
    for(const x of addedEmp){
      e += x?.total || 0 ;
    }
    setExpense(e);
  },[addedEmp]);

  useEffect(() => {
    setAddedEmp([]);
    setMisNote('');
    setActions(false);
  }, [month, year, hq]);

  useEffect(()=>{
    if(!month || !year || !hq)return ;

    viewMiscellaneousCost({month:month?.value, year:year?.label, hq:hq?.value}).then(({data})=>{
      if(data.headquarter){
        const emp = serialise(data.table);
        setEmployee(emp);
        setActions(true);
        setBillId(data._id);
        setEditing(false);
        setAddedEmp(emp);
      }else{
        const emp = serialise(data);
        setEmployee(emp);
        setEditing(false);
      }
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[month, year, hq]) ;

  const handleEditRow = useCallback((row)=>{
    
    if(rowEditing)return customToast.error("Please Save Your Changes");
    upNoteRef.current = row?.note || '';
    upCostRef.current = row?.total || 0;
    
    setEditRow(row?.note);
    setRowEditing(true);

  },[rowEditing]);

  const submitEditedRow = useCallback((prop)=>{
    const editedObj = addedEmp.find((emp, index) => {
      return emp.note === prop.note;
    })
    editedObj.total = Number(upCostRef.current);  
    setEditRow(null);
    setRowEditing(false);

  },[addedEmp]);

  const handleDelete = useCallback(()=>{
    putMiscellaneousCost({
      isDeleting:true,
      _id: billId
    }).then((data)=>{
      customToast.success("Bill Deleted Successfully");
    }).catch((err)=>{
      customToast.error("Sorry Something Went Wrong!");
    })
    setEmployee([]);
    setHq(null);
  },[billId]);

  const deleteRowFromAddedEmployee = useCallback((prop) => {
    let newAddedEmp = [];
    addedEmp.map((emp, index) => {
      if (emp.note !== prop){
        newAddedEmp = [...newAddedEmp, emp];
      }
    });
    setAddedEmp(newAddedEmp);
  },[addedEmp]);

  const submitBill = useCallback((e)=>{
    e.preventDefault();
    if(rowEditing)return customToast.info("Please Save Your Changes!");
    putMiscellaneousCost({
      month:month?.value,
      year:year?.label,
      hq:hq?.value,
      payload:addedEmp,
      _id: billId,
    }).then((data)=>{
      customToast.success("Bill Saved Successfully");
    }).catch((err)=>{
      customToast.error("Sorry Something Went Wrong!");
    })
    setEmployee([]);
    setHq(null);
  },[addedEmp,hq,month,year,editing,rowEditing]);

  const addMiscellaneousHandler = useCallback((e) => {

    e.preventDefault();
    const note = {
      note: misNote,
    }
    if(!month || !year || !hq)return customToast.error("Please Select Mandatory Fields");
    if(editing)return customToast.error("Sorry can't do this for previous bill");
    setMisNote('');
    setAddedEmp([note, ...addedEmp]);

  }, [month, year, hq, misNote, addedEmp, editing]);

  const columns = useMemo(
    () => [
      {
        Header: "Note",
        accessor: "note",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Total",
        accessor: "total",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"total",
        Cell: (props) => {
          const editable = props?.row?.original?.note === editRow;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.total}
              onChange={(e) => {
                upCostRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.total}</span>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        minWidth: 120,
        maxWidth: 120,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const editable = props?.row?.original?.note === editRow;
          return editable ? (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <AiFillSave type="button" className="icon-color-green mx-3" size={24} onClick={(e) => {
                  e.preventDefault();
                  submitEditedRow(props?.row?.original);
                }}/>
              </span>
            </div>
          ) : (
            <div className="d-flex w-100 justify-content-center">
              <span className="react-table-view-link" >
                <RiEdit2Fill type="button" className="icon-color-green mx-3" size={25} onClick={(e) => {
                  e.preventDefault();
                  handleEditRow(props?.row?.original);
                }}/>
              </span>
              <span className="react-table-view-link mx-3" >
                <MdDelete type="button" className="button-delete__icon" size={25} onClick={(e) => {
                  e.preventDefault();
                  setDeleteRowProp(props?.row?.original?.note);
                  setShowDelete(true);
                }} />
              </span>
            </div>
          );
        }
      },
    ],
    [editRow, handleEditRow, submitEditedRow]
  );

  const EditPopup = ({ type }) => {

    return (
      <section className="edit-popup" style={{ zIndex: 100 }}>
        <div className="edit-content">

          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete All</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowDelete(false)}
            />
          </div>

          <div className="mt-4">
            <p>Are you sure you want to delete the entry of {deleteRowProp} from the month of {month.label}? </p>
          </div>

          <button
            type="submit"
            className="button-delete mt-4"
            onClick={() => {
              deleteRowFromAddedEmployee(deleteRowProp)
              setShowDelete(false)
            }}
          >
            <span className="button-delete__icon-container">
              <MdDeleteOutline className="button-delete__icon" />
            </span>
            <span className="button-delete__text">Delete</span>
          </button>
          
        </div>
      </section>
    );
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">

        {
          showDelete ?
          <EditPopup /> :
          null
        }

        <PageTitle title="Employee" previousUrl="/profit-analysis" isDelete={billId !== '' ? true : false} toggle={handleDelete}/>
        <section className="area-creation-content__form">
          <form >
            <div className="d-flex gap-5 justify-content-start">
              <div className="d-flex flex-wrap gap-5">
                <div className="util-tp-filter">
                  <p>
                    Select Month <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Month"
                    className="mt-3"
                    options={monthOptions}
                    value={month}
                    onChange={(e) => setMonth({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Year <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={year}
                    onChange={(e) => setYear({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Headquarter <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select HQ"
                    className="mt-3"
                    options={headquarterSelect}
                    value={hq}
                    onChange={(e) => setHq({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Write Note for Miscellaneous <span className="asterisk-imp">*</span>
                  </p>
                  <input
                    placeholder="Enter note for miscellaneous"
                    className="mt-3"
                    onChange={(e) => setMisNote(e.target.value)}
                    value={misNote.charAt(0).toUpperCase()+misNote.slice(1)}
                  />
                </div>
                <div className="util-tp-filter mt-1">
                  <button type="button" className="button-blue-gradient mt-5" onClick={addMiscellaneousHandler}>
                    Add Miscellaneous
                  </button>
                </div>
              </div>
              <ProfitAnalysisBox row1={`${addedEmp.length} UNITS`} 
                row2={`Period: ${month?.label ? month?.label : ""} ${year?.label} ${new Date(year?.label, Number(month?.value)+1, 0).getDate() ? " | "+new Date(year?.label, Number(month?.value)+1, 0).getDate()+" Days" : ""}`}
                row3={`Rs ${expense}`} row4="TOTAL COST"
              />
            </div>
          </form>
        </section>
      </div>
      <div className="filter-table pe-4">
        <Table columns={columns} data={addedEmp} />
      <button type="button" className="button-submit" onClick={submitBill}>
        {actions ? "Over Ride" : "Submit" }
      </button>
      </div>
    </main>
  );
};

export default MiscellaneousCost;
