import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../../components/PageTitle";
import { formatDate } from "../../../../../../utils/helper";
import { RiCloseCircleLine } from "react-icons/ri";
import { useState } from "react";
import { Accordion } from "react-bootstrap";

const CreationViewDCSDetails = () => {
  const location = useLocation();
  console.log(location.state)
  const [showPopup, setShowPopup] = useState(false);

  const AddtionalDetails = ({ data }) => {
  
      return (
        <section className="edit-popup">
          <div className="edit-content">
            <div className="edit-popup__heading">
              <h2 className="web-app__heading">Additional Details</h2>
              <RiCloseCircleLine
                className="edit-popup__close"
                onClick={() => setShowPopup(false)}
              />
            </div>
  
            <form>
              <div className="edit-form">
  
              <section className="">
                <div className="util-inner-info-box d-flex justify-content-between">
                <div>
                  <h3>Created At</h3>
                  <p>{formatDate(location?.state?.createdAt)}</p>
                  </div>
                  {
                    location?.state?.isApproved === 1 ? <div>
                    <h3>Approved By</h3>
                    <p>{location?.state?.authorizedBy ? location?.state?.authorizedBy : "NA"}</p>
                    </div> : (
                      location?.state?.isApproved === -1 ? <div>
                      <h3>Rejected By</h3>
                      <p>{location?.state?.authorizedBy ? location?.state?.authorizedBy : "NA"}</p>
                      </div> : null
                    )
                  }
                </div>
              </section>
                <div className="user-detail-box">
                  {/* <h4>Visible To</h4> */}
                  <Accordion className="mt-4" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Visible To</h4></Accordion.Header>
                    <Accordion.Body className="custom-scrollbar custom-scrollable" style={{backgroundColor: `var(--color-primary-dark)`, maxHeight: '30vh'}}>
                      {location?.state?.accessDoctor?.map((e, i) => (
                        <div key={i} className="acc-body-card mt-2">
                          <p className="d-flex align-items-center px-4">{e.firstName} {e.lastName}</p>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                  {/* {
                    location?.state?.accessDoctor?.map((item, idx) => (
                      <p key={idx}>{item.firstName} {item.lastName}</p>
                    ))
                  } */}
                </div>
              </div>
            </form>
          </div>
        </section>
      );
    };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`Doctor Details`} hierarchy={true} isMoreInfo={true} toggle={setShowPopup}/>
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{location?.state?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Degree</h4>
          <p>{location?.state?.degree}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{location?.state?.workingArea?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Specialization</h4>
          <p>{location?.state?.specialization}</p>
        </div> 
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Address</h4>
          <p>{location?.state?.address}</p>
        </div>

        <div className="user-detail-box">
          <h4>Anniversary</h4>
          <p>{formatDate(location?.state?.anniversary)}</p>
        </div>

        <div className="user-detail-box">
          <h4>BirthDay</h4>
          <p>{formatDate(location?.state?.birthday)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Category</h4>
          <p>{location?.state?.category?.label}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Clinic Contact</h4>
          <p>{location?.state?.contact}</p>
        </div>

        <div className="user-detail-box">
          <h4>Doctor Code</h4>
          <p>{location?.state?.doctorCode}</p>
        </div>

        <div className="user-detail-box">
          <h4>E-mail</h4>
          <p>{location?.state?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Mobile</h4>
          <p>{location?.state?.mobile}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>Clinic's Address</h4>
          <p>{location?.state?.address}</p>
      </div>

      <div className="user-detail-box">
          <h4>Extra Info</h4>
          <p>{location?.state?.extraInfo}</p>
      </div>
      </section>

      {showPopup && <AddtionalDetails data={location?.state} />}
    </div>
  );
};

export default CreationViewDCSDetails;
