export const title = "Settings";

export const links = [
  {
    name: "Create Holidays",
    route: "create",
    accessor: "create",
  },
  {
    name: "Preferences",
    route: "preferences",
    accessor: "preferences",
  },
  {
    name: "Restore Deleted",
    route: "restore-deleted",
    accessor: "restore-deleted",
  },
  {
    name: "User Controls",
    route: "user-controls",
    accessor: "user-controls",
  },
  {
    name: "Doctor Controls",
    route: "doctor-controls",
    accessor: "doctor-controls",
    new: true
  }
  // {
  //   name: "Backlog Reports",
  //   route: "backlog-reports",
  //   accessor: "backlog-reports",
  // },
];
