
import { useLocation, useNavigate } from "react-router-dom";

import { RiCapsuleFill, RiUser3Fill, RiGiftFill, RiNurseFill } from "react-icons/ri";
import { MdArrowBackIosNew } from "react-icons/md";
import { formatDate, formatTime, monthOptions } from "../../../../../utils/helper";
import { Accordion } from "react-bootstrap";

const ApprovalCPDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Call Planning Details
          </span>
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(location?.state?.date)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            </div>
            <div className="util-inner-info-box">
              <h3>Employee Name</h3>
              <p>
                {location?.state?.submitter.firstName} {location?.state?.submitter.lastName}
              </p>
            </div>

          </div>
        </div>


        <div className="dcs-call-wrapper">
          <div className="w-[22rem]">
            <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.doctors?.length > 0 ? "Planned Doctor" : null}
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                {location?.state?.doctors?.map((e, i) => (
                  (e?.products.length > 0 || e?.gifts.length > 0) ? (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.doctor?.name}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products?.length > 0 ? "Samples DETAILS" : null}
                      </h5>
                      {e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                            {/* ---{" "} */}
                            {/* {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0} */}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.gift}
                            {/* --- */}
                            {/* {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0} */}
                          </div>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                  ) : (
                    <Accordion.Header key={e._id} className="mb-4 custom-accordion-header">
                    <span className="d-flex align-items-center">
                      {<RiNurseFill className="me-3 fs-2" />} {e?.doctor?.name}
                    </span>
                  </Accordion.Header>
                  )
                ))}
              </Accordion>
            </div>
            <div className="chemist-call-div">
              <h2 className="web-app__heading report-heading mt-5">
                {location?.state?.chemists?.length > 0 ? "Planned Chemist" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {location?.state.chemists.map((e, i) => (
                  (e?.products.length > 0 || e?.gifts.length > 0) ? (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.chemist?.businessName}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products.length > 0 ? "Samples DETAILS" : null}
                      </h5>
                      {e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                            {/* ---{" "} */}
                            {/* {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0} */}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.gift}
                            {/* --- */}
                            {/* {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0} */}
                          </div>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                  ) : (
                    <Accordion.Header key={e._id} className="mb-4 custom-accordion-header">
                    <span className="d-flex align-items-center">
                      {<RiNurseFill className="me-3 fs-2" />} {e?.chemist?.businessName}
                    </span>
                  </Accordion.Header>
                  )
                ))}
              </Accordion>
            </div>
            <div className="stockist-call-div">
              <h2 className="web-app__heading mt-5">
                {location?.state?.stockists?.length > 0 ? "Planned Stockist" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {location?.state.stockists.map((e, i) => (
                  (e?.products.length > 0 || e?.gifts.length > 0) ? (
                    <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.stockist?.businessName}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products.length > 0 ? "Samples DETAILS" : null}
                      </h5>
                      {e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.name}
                            {/* ---{" "} */}
                            {/* {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0} */}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.gift}
                            {/* --- */}
                            {/* {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0} */}
                          </div>
                        </div>
                      ))}

                    </Accordion.Body>
                  </Accordion.Item>
                  ): (
                    <Accordion.Header key={e._id} className="mb-4 custom-accordion-header">
                    <span className="d-flex align-items-center">
                      {<RiNurseFill className="me-3 fs-2" />} {e?.stockist?.businessName}
                    </span>
                  </Accordion.Header>
                  )
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalCPDetail;
