import { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ProgressAverage from "../../../components/ProgressAverage";
import { filterStyles } from "../../public/stylesheets/selectStyles";
import { getReminderForDashboard } from "../../../services/reminder";
import { formatDate, monthOptions, yearOptions } from "../../../utils/helper";
import SalesGraph from "./SalesGraph";
import ReportsGraph from "./ReportsGraph";
import {
  chemCallAverage,
  docCallAverage,
  getAnniversariesForDashboard,
  getAvgDailyVisits,
  getBirthdaysForDashboard,
  getPrimaryAmount,
  getSecondaryAmount,
  getTargetAmount,
  missedReportsForDashboard,
  stkCallAverage,
  totalPOBStats,
} from "../../../services/dashboard";

//Images used for stats
import WhiteCircles from "./../../../views/public/img/white-circles.png";
import DocVisited from "./../../../views/public/img/doctor-visited.png";
import DocMissed from "./../../../views/public/img/doctor-missed.png";
import ChemVisited from "./../../../views/public/img/chem-visited.png";
import ChemMissed from "./../../../views/public/img/chem-missed.png";
import DocPOB from "./../../../views/public/img/white-circles.png";
import { generateSelectUser } from "../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../services/utilities";
import { useSelector } from "react-redux";
import { FcFaq } from "react-icons/fc";
import customToast from "../../../components/CustomToast";
import { MdOutlineRefresh } from "react-icons/md";
import { selectUserStyles } from "../../public/stylesheets/selectStyles";

const Dashboard = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const [month, setMonth] = useState(monthOptions[new Date().getUTCMonth()]);
  const [year, setYear] = useState(yearOptions[0]);
  const [reminderData, setReminderData] = useState([]);
  const [birthdayData, setBirthdayData] = useState([]);
  const [anniversaryData, setAnniversaryData] = useState([]);

  const [totalDocVisit, setTotalDocVisit] = useState();
  const [totalChemVisit, setTotalChemVisit] = useState();
  const [totalStkVisit, setTotalStkVisit] = useState();
  const [totalDoctors, setTotalDoctors] = useState();
  const [metDoctors, setMetDoctors] = useState();

  const [actualDocVisit, setActualDocVisit] = useState();
  const [actualChemVisit, setActualChemVisit] = useState();
  const [actualStkVisit, setActualStkVisit] = useState();

  const [docPOB, setDocPOB] = useState(0);
  const [chemPOB, setChemPOB] = useState(0);
  const [stkPOB, setStkPOB] = useState(0);
  const [targetAmount, setTargetAmount] = useState(0);
  const [primaryAmount, setPrimaryAmount] = useState(0);
  const [secondaryAmount, setSecondaryAmount] = useState(0);
  const [avgDailyVisits, setAvgDailyVisits] = useState(0);

  useEffect(() => {
    const fetchData = async() => {
      try {
        let payload = {
          month: Number(month?.value),
          year: Number(year?.label),
          user: selectedUser ? selectedUser?.value : loggedIn?.user?._id,
        };

        const res = await getAvgDailyVisits(payload);
        setAvgDailyVisits(res?.data);
      } catch (error) {
        customToast?.error("Error Occurred !");
      }
    };
    fetchData();
  },[month, year, selectedUser]);

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  useEffect(() => {
    getReminderForDashboard().then((response) => {
      setReminderData([...response.data]);
    });

    getBirthdaysForDashboard().then((response) => {
      setBirthdayData([...response.data.data]);
    });  

    getAnniversariesForDashboard().then((response) => {
      setAnniversaryData([...response.data.data]);
    });
  }, []);

  useEffect(() => {
    let data = {
      selectedUser: selectedUser?.value,
      month: Number(month?.value),
      year: Number(year?.label),
    };

    const asyncFetch = async () => {
      // const docCA = await docCallAverage(data);

      // setDoctorCallAverage(docCA.data);
      // const chemCA = await chemCallAverage(data);
      // setChemistCallAverage(chemCA.data);

      // const stkCA = await stkCallAverage(data);
      // setStockistCallAverage(stkCA.data);

      const missedData = await missedReportsForDashboard(data);
      const pobData = await totalPOBStats(data);
      const primarySale = await getPrimaryAmount(data);
      const secondarySale = await getSecondaryAmount(data);
      const targetData = await getTargetAmount(data);
      
      setActualDocVisit(missedData?.data?.actualDocVisit);
      setTotalDocVisit(missedData?.data?.totalDocVisit);
      setTotalDoctors(missedData?.data?.totalDoctors);
      setMetDoctors(missedData?.data?.doctorsMet) ;
      setActualChemVisit(missedData?.data?.actualChemVisit);
      setTotalChemVisit(missedData?.data?.totalChemVisit);

      setActualStkVisit(missedData?.data?.actualStkVisit);
      setTotalStkVisit(missedData?.data?.totalStkVisit);

      setDocPOB(pobData?.data?.docPOBData);
      setChemPOB(pobData?.data?.chemPOBData);
      setStkPOB(pobData?.data?.stkPOBData);

      setPrimaryAmount(primarySale.data ? primarySale?.data : 0);
      setSecondaryAmount(secondarySale.data ? secondarySale?.data : 0);
      setTargetAmount(targetData.data ? targetData?.data : 0);
    };

    asyncFetch();
  }, [month, year, selectedUser]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="main-content">
      <div className="dashboard__content">
        <div className="row dashboard__graph-container">
          <h3>
            Statistics for {month.label} {year.label}
          </h3>
          <div className="col-lg-6" style={{ marginBottom: "1rem" }}>
            <div className="dashboard__graph">
              <div>
                <SalesGraph
                  primaryData={primaryAmount}
                  secondaryData={secondaryAmount}
                  targetData={targetAmount}
                />
              </div>
              <h3 className="dashboard-graph-labels">
                Target vs Primary vs Secondary
              </h3>
            </div>
          </div>

          <div className="col-lg-6" style={{ marginBottom: "1rem" }}>
            <div className="dashboard__graph">
              <div>
                {/* <DashGraph filter={{ month: month.value, year: year.value }} /> */}
                <ReportsGraph
                  month={month?.value}
                  year={Number(year?.label)}
                  selectedUser={selectedUser?.value}
                />
              </div>
              <h4 className="dashboard-graph-labels">Reports Submitted</h4>
            </div>
          </div>

          <div className="dashboard__filters">
            <Select
              name="month"
              id="month"
              value={month}
              options={monthOptions}
              onChange={(e) => setMonth({ value: e.value, label: e.label })}
              styles={filterStyles}
              placeholder="Set Month"
            />
            <Select
              name="year"
              id="year"
              value={year}
              options={yearOptions}
              onChange={(e) => setYear({ value: e.value, label: e.label })}
              styles={filterStyles}
              placeholder="Set Year"
            />
            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <div className="d-flex flex-row align-items-center">
              <Select
                name="user"
                id="user"
                value={selectedUser}
                options={employeeSelect}
                onChange={(e) => setSelectedUser({ ...e })}
                styles={filterStyles}
                placeholder="Select User"
              />
              </div>
            ) : null}
            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <span className="d-flex flex-row align-items-center">
              <MdOutlineRefresh size={25} className="text-white p-1" style={{cursor:"pointer", backgroundColor:"#2ecc71", borderRadius: "50%"}} onClick={() => setSelectedUser(null)}/>
                </span>
            ) : null}
          </div>
        </div>

        <div className="dashboard-stats">
          <div
            className="dashboard-stats__metrics dashboard-stats__metrics-1"
            onClick={() => navigate("/utilities/missed-doctor-reports")}
          >
            <div className="dashboard-stats__metrics-figures percentage-holder flex-column">
              <img
                src={WhiteCircles}
                alt="..."
                className="dashboard-stats__metrics-white-circle"
              />
              <h1 className="smaller">{docPOB?.toFixed(2)}</h1>
              <h2>DOCTOR POB</h2>
            </div>
            <div
              className="dashboard-stats__metrics-figures"
              onClick={() => navigate("/utilities/missed-doctor-reports")}
            >
              <h2>Doctor Calls</h2>
              <p>
                {actualDocVisit === undefined || totalDocVisit === undefined
                  ? "Calculating..."
                  : `${actualDocVisit} / ${totalDocVisit}`}
              </p>
            </div>
          </div>
          <div
            className="dashboard-stats__metrics dashboard-stats__metrics-2"
            onClick={() => navigate("/utilities/missed-doctor-reports")}
          >
            <div className="dashboard-stats__metrics-figures percentage-holder flex-column">
              <img
                src={WhiteCircles}
                alt="..."
                className="dashboard-stats__metrics-white-circle"
              />
              <h1 className="smaller">{chemPOB?.toFixed(2)}</h1>
              <h2>CHEMIST POB</h2>
            </div>
            <div
              className="dashboard-stats__metrics-figures"
              onClick={() => navigate("/utilities/missed-doctor-reports")}
            >
              <h2>Chemist Calls</h2>
              <p>
                {actualChemVisit === undefined || totalChemVisit === undefined
                  ? "Calculating..."
                  : `${actualChemVisit} / ${totalChemVisit}`}
              </p>
            </div>
          </div>
          <div
            className="dashboard-stats__metrics dashboard-stats__metrics-3"
            onClick={() => navigate("/utilities/missed-doctor-reports")}
          >
            <div className="dashboard-stats__metrics-figures percentage-holder flex-column">
              <img
                src={WhiteCircles}
                alt="..."
                className="dashboard-stats__metrics-white-circle"
              />
              <h1 className="smaller">{stkPOB?.toFixed(2)}</h1>
              <h2>STOCKIST POB</h2>
            </div>
            <div className="dashboard-stats__metrics-figures">
              <h2>Stockist Calls</h2>
              <p>
                {actualStkVisit === undefined || totalStkVisit === undefined
                  ? "Calculating..."
                  : `${actualStkVisit} / ${totalStkVisit}`}
              </p>
            </div>
          </div>
          <div
            className="dashboard-stats__metrics dashboard-stats__metrics-4"
            onClick={() => navigate("/utilities/pob-reports")}
          >
            <div className="percentage-holder">
              <img
                src={WhiteCircles}
                alt="..."
                className="dashboard-stats__metrics-white-circle"
              />

              {Math.round((metDoctors / totalDoctors) * 100) ? (
                <h1>
                  {totalDoctors
                    ? Math.round((metDoctors / totalDoctors) * 100)
                    : 0}
                  %
                </h1>
              ) : (
                <h1>0%</h1>
              )}
            </div>
            <div className="dashboard-stats__metrics-figures">
              <h2>Doctors Met/Total</h2>
              <p>{metDoctors ? metDoctors : 0}/{totalDoctors ? totalDoctors : 0}</p>
            </div>
          </div>
          <div
            className="dashboard-stats__metrics dashboard-stats__metrics-5"
            onClick={() => navigate("/utilities/primary-sales-reports")}
          >
            <div className="percentage-holder">
              <img
                src={WhiteCircles}
                alt="..."
                className="dashboard-stats__metrics-white-circle"
              />

              {Math.round((primaryAmount / targetAmount) * 100) ? (
                <h1>
                  {targetAmount
                    ? Math.round((primaryAmount / targetAmount) * 100)
                    : 0}
                  %
                </h1>
              ) : (
                <h1>0%</h1>
              )}
            </div>
            <div className="dashboard-stats__metrics-figures">
              <h2>Primary Sale</h2>
              <p>{primaryAmount ? primaryAmount : 0}</p>
            </div>
          </div>
          <div
            className="dashboard-stats__metrics dashboard-stats__metrics-6"
            onClick={() => navigate("/utilities/secondary-sales-reports")}
          >
            <div className="percentage-holder">
              {Math.round((secondaryAmount / targetAmount) * 100) ? (
                <h1>
                  {targetAmount
                    ? Math.round((secondaryAmount / targetAmount) * 100)
                    : 0}
                  %
                </h1>
              ) : (
                <h1>0%</h1>
              )}
              <img
                src={WhiteCircles}
                alt="..."
                className="dashboard-stats__metrics-white-circle"
              />
            </div>
            <div className="dashboard-stats__metrics-figures">
              <h2>Secondary Sale</h2>
              <p>{secondaryAmount ? secondaryAmount : 0}</p>
            </div>
          </div>
        </div>

        <div className="dashboard-upcoming-stats">
          <div className="reminder-row">
            <h2 className="web-app__heading">Reminders</h2>

            <div className="reminder-card-container">
              {reminderData.length > 0 ? (
                reminderData?.map((el) => {
                  // console.log(el)
                  return (
                    <div className="reminder-card">
                      <h3 className="reminder-card-title">{el?.title}</h3>
                      <p className="reminder-card-desc">{el?.description}</p>

                      {el?.jointReminder.length > 0 && (
                        <p className="reminder-card-person">
                          Joint Reminder:{" "}
                          <span>
                            {el?.jointReminder?.map((e, idx) =>
                              el?.jointReminder?.length - 1 === idx
                                ? `${e?.firstName} ${e?.lastName}, `
                                : `${e?.firstName} ${e?.lastName}, `
                            )}
                          </span>
                        </p>
                      )}
                      <div className="reminder-card-info">
                        <p className="reminder-card-date">
                          Date: {formatDate(el?.date)}
                        </p>
                        <p className="reminder-card-time">Time: {el?.time}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="approved-text">No Reminders for today</span>
              )}
            </div>
          </div>

          <div className="reminder-row">
            <h2 className="web-app__heading">Birthdays</h2>

            <div className="reminder-card-container">
              {birthdayData.length > 0 ? (
                birthdayData?.map((el) => {
                  return (
                    <div className="reminder-card">
                      <h3 className="reminder-card-title">
                      {el?.name}
                      <div>
                      <span className="reminder-card-title-name">{el?.workingArea?.name}</span> 
                      </div> 
                      </h3>

                      {/* <h3 className="reminder-card-title">{el?.name}    {el?.area[0]?.name}</h3> */}
                      {/* <p className="reminder-card-desc">
                        Area: {el?.area[0]?.name}
                      </p> */}

                      <div className="reminder-card-info">
                        <p className="reminder-card-date">
                          Birthday: {formatDate(el?.birthday)}
                        </p>
                        {/* <p className="reminder-card-time">Time: {el?.time}</p> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="approved-text">No Birthdays today</span>
              )}
            </div>
          </div>

          <div className="reminder-row">
            <h2 className="web-app__heading">Marriage Anniversaries</h2>

            <div className="reminder-card-container">
              {anniversaryData.length > 0 ? (
                anniversaryData?.map((el) => {
                  console.log(el);
                  return (
                    <div className="reminder-card">
                      <h3 className="reminder-card-title">
                      {el?.name} 
                      <div>
                      <span className="reminder-card-title-name">{el?.workingArea?.name}</span> 
                      </div> 
                      </h3>
                      {/* <h3 className="reminder-card-title">{el?.name}</h3>
                      <p className="reminder-card-desc">
                        Area: {el?.area[0]?.name}
                      </p> */}

                      <div className="reminder-card-info">
                        <p className="reminder-card-date">
                          Date: {formatDate(el?.anniversary)}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="approved-text">
                  No Marriage Anniversaries today
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-cards">
          <div className="dashboard-card">
            <h3>Daily Average Visits</h3>
            <div className="dashboard-card__chart">
              <ProgressAverage
                value={Number(avgDailyVisits?.docs).toFixed(2)}
                // text={doctorCallAverage ? `${round(doctorCallAverage, 1)}` : 0}
                text={Number(avgDailyVisits?.docs).toFixed(2)}
              />
            </div>
              <h3>Doctor Visits</h3>
            <div className="dashboard-card__details"></div>
          </div>
          <div className="dashboard-card">
            <h3>Daily Average Visits</h3>
            <div className="dashboard-card__chart">
              <ProgressAverage
                value={Number(avgDailyVisits?.chems).toFixed(2)}
                text={
                  // chemistCallAverage ? `${round(chemistCallAverage, 1)}` : 0
                  Number(avgDailyVisits?.chems).toFixed(2)
                }
              />
            </div>
            <h3>Chemists Visits</h3>
            <div className="dashboard-card__details"></div>
          </div>
          <div className="dashboard-card">
            <h3>Daily Average Visits</h3>
            <div className="dashboard-card__chart">
              <ProgressAverage
                value={Number(avgDailyVisits?.stks).toFixed(2)}
                text={
                  // stockistCallAverage ? `${round(stockistCallAverage, 1)}` : 0
                  Number(avgDailyVisits?.stks).toFixed(2)
                }
              />
            </div>
            <h3>Stockists Visits</h3>
            <div className="dashboard-card__details"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
