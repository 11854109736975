import { MdArrowBackIosNew, MdDelete } from "react-icons/md";
import {IoIosArrowDropdownCircle, IoIosArrowDropupCircle} from "react-icons/io"
import { Link, useNavigate } from "react-router-dom";
import { PiDotsThreeCircleLight } from "react-icons/pi";

function PageTitle({ title, isCreation , hierarchy, isDelete, toggle,isDropdown, isDropup, isCRM, previousUrl, anchor, anchorUrl, anchorContent, isMoreInfo }) {
  const navigate = useNavigate();

  return (
    <>
      <section className="admin-creation-content__heading justify-content-between">
        <div className="d-flex justify-content-center align-items-center">
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() =>
              previousUrl ? navigate(previousUrl) : hierarchy ? navigate(-1) : isCreation ? navigate("/creation-menu") : isCRM ? navigate("/crm") : navigate("/admin-panel")
            }
          />
          <h2 className="web-app__heading">{title}</h2>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-4">
          { anchor ? 
            <Link
              to={anchorUrl}
              style={{color:"var(--color-tertiary)"}}
            >{anchorContent}
            </Link> :
            null }
          { isDelete ? <MdDelete
            className="icon-color-error ms-auto"
            style={{ cursor: "pointer" }}
            onClick={() => toggle(true)}
          /> : null }
          { isDropdown ? <IoIosArrowDropdownCircle
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            onClick={() => toggle(true)}
          /> : null }
          { isDropup ? <IoIosArrowDropupCircle
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            onClick={() => toggle(false)}
          /> : null }
          { isMoreInfo ? <PiDotsThreeCircleLight
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            title="More Info"
            onClick={() => toggle(true)}
          /> : null }
          
        </div>
      </section>
    </>
  );
}

export default PageTitle;
