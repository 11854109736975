import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
  } from "react";
  import { Link } from "react-router-dom";
  import { useSelector, useDispatch } from "react-redux";
  import Select from "react-select";
  import customToast from "../../../components/CustomToast";
  import {
    clearStockists,
    setStockists,
  } from "../../../reducers/targets/stockist";
  import {
    clearHeadquarters,
    setHeadquarters,
  } from "../../../reducers/locations/hqReducer";
  import serialise, { generateSelectData } from "../../../utils/serialiseResponse";
  import Table from "../../../components/Table";
  import {
    adminStyles,
    selectStyles,
  } from "../../public/stylesheets/selectStyles";
  import { yearOptions, monthOptions, returnMonthFromIndex, formatDate } from "../../../utils/helper";
  import { addGrossPrimarySale, viewGrossPrimarySale } from "../../../reducers/Sales/primarySales";
  import { fileToBase64 } from "../../../utils/helper";
import { dcrStyles } from "../../public/stylesheets/selectStyles";
  
  
  const GrossPrimarySales = () => {
    const dispatch = useDispatch();
    const inputRef = useRef(null); // for upload file input
    const stockistData = useSelector(({ stockist }) => stockist);
    const headquarters = useSelector(({ headquarter }) => headquarter);
    const grossPrimarySale = useSelector(({primarySales})=>primarySales);
  
    const [date, setDate] = useState("");
    const [stk, setStk] = useState("");
    const [headquarter, setHeadquarter] = useState("");
    const [uploadData, setUploadData] = useState(""); 
    const [totalAmt, setAmt] = useState();
    const [month, setMonth] = useState({
      value: new Date().getMonth(),
      label: returnMonthFromIndex(new Date().getMonth()),
    });
    const [year, setYear] = useState(yearOptions[0]);
    const [isVisible, setIsVisible] = useState(true);
  
    const stockistselectData = useMemo(() => {
      return stockistData?.data?.filter((e) => headquarter?._id === e?.city?._id);
    }, [headquarter]);

    const stockistSelect = useMemo(
      () => generateSelectData(stockistselectData, "businessName"),
      [stockistselectData]
    );
    const headquarterSelect = useMemo(
      () => generateSelectData(headquarters.data, "name"),
      [headquarters]
    );
    
    let changeHandler = (e) => {
      const validFiles = Array.from(e.target.files);
      setUploadData([]);
      Promise.all(validFiles.map(fileToBase64))
        .then((base64Images) => {
          setUploadData(base64Images);
        })
        .catch((error) => {
          customToast.error('Error adding images');
        });
    };
  
    const resetForm = () => {
      setDate("");
      setHeadquarter("");
      setStk("");
      setUploadData("");
      setAmt("");
    };
  
    useEffect(() => {
      dispatch(setStockists());
      dispatch(setHeadquarters());
      return () => {
        dispatch(clearStockists());
        dispatch(clearHeadquarters());
      };
    }, [dispatch]);

    useEffect(()=>{
      let timer = setTimeout(()=>{
        setIsVisible(false);
      },10000);
      return ()=> clearTimeout(timer);
    },[]);

    const handleViewGrossSales = (e) =>{
      e.preventDefault();
      if(!month) return customToast.error("Select month");
      try {
        dispatch(viewGrossPrimarySale({month:month?.value, year:year?.label}));
      } catch (error) {
        customToast.error("Error encountered");
      }
    };

    const handleSubmitAmt = (e) =>{
      e.preventDefault();
      if(!date || !headquarter || !stk || !totalAmt){
        return customToast.error("Select All Fields");
      }

      let data = {
        date,
        headquarter,
        stockist : stk,
        amt : totalAmt,
        images : uploadData
      };
      try {
        dispatch(addGrossPrimarySale(data));
        resetForm();
        customToast.success("Amount submitted successsfully");
      } catch (error) {
        return customToast.error("Error Occurred");
      }
    };

    const tableData = useMemo(()=>serialise(grossPrimarySale?.data),[grossPrimarySale]);

    const columns = useMemo(
        () => [
            // {
            //     Header: "Sr. No",
            //     accessor: "sr",
            //     minWidth: 50,
            //     disableFilters: true,
            //     disableSortBy:true,
            //     maxWidth: 50,
            //     showTotal:true,
            //     placeholderTotal: "Total"
            //   },
              {
                Header: "Date",
                accessor: "date",
                disableFilters: true,
                minWidth:50,
                maxWidth:50,
                Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
              },
              {
                Header: "HQ",
                accessor: "headquarter",
                minWidth: 50,
                maxWidth: 50,
              },
              {
                Header: "Stockist",
                accessor: "stockist",
                minWidth: 50,
                maxWidth: 50,
              },
              {
                Header: "Amount",
                accessor: "amount",
                minWidth: 50,
                disableFilters: true,
                maxWidth: 50,
                showTotal: true,
                totalAccessor:"amount"
              },      
        ]
    )

    return (
      <main className="main-content admin-content">
        <div className="expense" style={{padding:"2rem"}}>
          <h3 className="web-app__heading">Gross Primary Sales</h3>
          <form onSubmit={handleViewGrossSales}>
            <div className="primarysales-filter" style={{marginBottom:"2rem"}}>
              <div className="util-tp-filter">
                <p className="mb-2">
                  Date <span className="asterisk-imp">*</span>
                </p>
                <input
                  type="date"
                  className=""
                  id="date-input"
                  placeholder="Date"
                  style={selectStyles}
                  value={date}
                  onChange={({ target }) => setDate(target.value)}
                />
              </div>
  
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Headquarter <span className="asterisk-imp">*</span>
                </p>
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={(e) => setHeadquarter({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
  
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Stockist <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={adminStyles}
                  options={stockistSelect}
                  placeholder="Select Stockist"
                  value={stk}
                  onChange={(e) => setStk({ ...e })}
                />
              </div>
              <div className="util-tp-filter">
                <p className="mb-2">Upload File</p>
                <input
                  ref={inputRef}
                  type="file"
                  placeholder="you can upload image, excel or pdf"
                  onChange={changeHandler}
                />
              </div>
              <div className="util-tp-filter">
                <p className="mb-2">
                  Total Amount <span className="asterisk-imp">*</span>
                </p>
                <input
                type="number"
                placeholder="Enter Amount"
                value={totalAmt}
                onChange={({target}) => setAmt(target?.value)}
                 />
              </div>
              <div className="util-tp-filter">
                <div className="mb-2">
                  <button
                    type="button"
                    onClick={handleSubmitAmt}
                    className="button-blue-gradient  mt-5"
                  >
                    Submit Amount
                  </button>
                </div>
              </div>
            </div>

          {isVisible && (
        <div className="admin-panel__alert" style={{ padding: '1rem', marginTop: '2rem' }}>
          <h2 style={{ marginBottom: '0' }}>Note</h2>
          <p>
            The below list shows only those Gross Primary Sales which are submitted by the loggedIn user. In order to view
            the complete list, visit ---&gt; Reports ---&gt; Primary Sales Reports or{' '}
            <Link to="/utilities/primary-sales-reports">
              <button style={{ background: 'none', border: 'none', color: 'white' }}>CLICK HERE !</button>
            </Link>
          </p>
        </div>
      )}
          <div className="view-gross-sales" style={{display:"flex", gap:"5rem"}}>
              <div className="util-tp-filter">
                <p>
                  Select Month <span style={{ color: "#e74c3caa" }}>*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                />
              </div>

              <div className="util-tp-filter">
                <p>
                  Select Year <span style={{ color: "#e74c3caa" }}>*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div>
              
              <div className="util-tp-filter" style={{marginLeft:"45rem"}}>
              <div className="mb-2">
                <button type="submit" className="button-blue-gradient mt-5">View Gross Sales</button>
              </div>
            </div>
            </div>
            <div className="filter-table" style={{ minHeight: "300px" }}>
              <Table columns={columns} data={tableData} />
            </div>
          </form>
        </div>
      </main>
    );
  };
  
  export default GrossPrimarySales;
  