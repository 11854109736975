import { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import serialise, { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { selectUserStyles, dcrStyles, adminStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import {
  formatDate,
  monthOptions,
  yearOptions
} from "../../../../utils/helper";
import { MdDeleteOutline, MdOutlineAdd, MdOutlineGroup } from "react-icons/md";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { IoIosAddCircleOutline, IoIosDoneAll } from "react-icons/io";
import Table from "../../../../components/Table/index.jsx";
import { addFinalCallPlanning, deleteCallPlanning, getMonthlyCallPlanning } from "../../../../services/callPlanning.js";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { AiOutlineStop } from "react-icons/ai";
import { RiCapsuleFill, RiCloseCircleLine, RiGiftFill, RiNurseFill } from "react-icons/ri";
import { viewTargets } from "../../../../services/targets.js";
import { viewGifts, viewProductV1 } from "../../../../services/products.js";
import { Accordion } from "react-bootstrap";
import { LuLoader2 } from "react-icons/lu";
import { PiDotsThreeCircleLight } from "react-icons/pi";

const CallPlanningForm = () => {
  const loggedIn = useSelector(({ user }) => user);
  const [month, setMonth] = useState(monthOptions[new Date().getUTCMonth()]);
  const [year, setYear] = useState(yearOptions[0]);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const sundayWorking = useSelector((state) => state.settings["sundayWorking"]);
  const [cpData, setCPData] = useState([])
  const [addPopup, setAddPopup] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [data, setData] = useState()

  const [doctors, setDoctors] = useState([])
  const [chemists, setChemists] = useState([])
  const [stockists, setStockists] = useState([])
  const [products, setProducts] = useState([])
  const [gifts, setGifts] = useState([])

  const setAddForm = (data, selectedUser) => {
    setData({
      date: data.date,
      selectedUser: selectedUser?.value,
      id: data._id
    });
  };

  const iconStyle = {
    fontSize: "2.3rem",
    cursor: "pointer"
  }
  const iconStyle2 = {
    fontSize: "2rem",
    cursor: "pointer"
  }

  const AddPopup = ({ data }) => {

    const [loading, setLoading] = useState(false);
    const [addPGPopup, setAddPGPopup] = useState(false);
    const [activeTab, setActiveTab] = useState("Doctor");
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDoctorProduct, setSelectedDoctorProduct] = useState([]);
    const [selectedDoctorGift, setSelectedDoctorGift] = useState([]);
    const [doctorsArray, setDoctorsArray] = useState([]);

    const [selectedChemist, setSelectedChemist] = useState(null);
    const [selectedChemistProduct, setSelectedChemistProduct] = useState([]);
    const [selectedChemistGift, setSelectedChemistGift] = useState([]);
    const [chemistsArray, setChemistsArray] = useState([]);

    const [selectedStockist, setSelectedStockist] = useState(null);
    const [selectedStockistProduct, setSelectedStockistProduct] = useState([]);
    const [selectedStockistGift, setSelectedStockistGift] = useState([]);
    const [stockistsArray, setStockistsArray] = useState([]);

    const [currentEntity, setCurrentEntity] = useState(null);

    const handleAdd = () => {
      if (activeTab === "Doctor" && selectedDoctor) {
        doctorsArray.find((doc) => doc.doctor._id === selectedDoctor._id) ? customToast.error("Doctor already added") :
        setDoctorsArray((prev) => [
          ...prev,
          {
            doctor: selectedDoctor,
            products: [],
            gifts: []
          },
        ]);
        setSelectedDoctor(null);
      } else if (activeTab === "Chemist" && selectedChemist) {
        chemistsArray.find((doc) => doc.chemist._id === selectedChemist._id) ? customToast.error("Chemist already added") :
        setChemistsArray((prev) => [
          ...prev,
          {
            chemist: selectedChemist,
            products: [],
            gifts: []
          },
        ]);
        setSelectedChemist(null);
      } else if (activeTab === "Stockist" && selectedStockist) {
        stockistsArray.find((doc) => doc.stockist._id === selectedStockist._id) ? customToast.error("Stockist already added") :
        setStockistsArray((prev) => [
          ...prev,
          {
            stockist: selectedStockist,
            products: [],
            gifts: []
          },
        ]);
        setSelectedStockist(null);
      }
    };

    const handleSubmitFinalCP = async (e) => {
      e.preventDefault();
      setLoading(true)

      if (doctorsArray.length === 0 && chemistsArray.length === 0 && stockistsArray.length === 0) {
        customToast.error("Please add atleast one entity")
        setLoading(false)
        return
      }

      try {
        await addFinalCallPlanning({
          doctors: doctorsArray,
          chemists: chemistsArray,
          stockists: stockistsArray,
          date: data.date,
          selectedUser: data.selectedUser
        })
        const res = await getMonthlyCallPlanning({ month: month?.value, year: year?.label, selectedUser: selectedUser?.value })
        setCPData([...res.data])
      } catch (error) {
        customToast.error("Error in adding call planning")
      } finally {
        setLoading(false)
        setAddPopup(false)
      }
    }

    const handleAddPG = async () => {

      if (activeTab === "Doctor" && (selectedDoctorProduct || selectedDoctorGift)) {
        setDoctorsArray((prev) =>
          prev.map(doc => doc.doctor._id === currentEntity ? {
            ...doc,
            products: [...doc.products, ...selectedDoctorProduct],
            gifts: [...doc.gifts, ...selectedDoctorGift],
          }
            : doc))
        setSelectedDoctorProduct([])
        setSelectedDoctorGift([])
      } else if (activeTab === "Chemist" && (selectedChemistProduct || selectedChemistGift)) {
        setChemistsArray((prev) =>
          prev.map(doc => doc.chemist._id === currentEntity ? {
            ...doc,
            products: [...doc.products, ...selectedChemistProduct],
            gifts: [...doc.gifts, ...selectedChemistGift],
          }
            : doc))
        setSelectedChemistProduct([])
        setSelectedChemistGift([])
      } else if (activeTab === "Stockist" && (selectedStockistProduct || selectedStockistGift)) {
        setStockistsArray((prev) =>
          prev.map(doc => doc.stockist._id === currentEntity ? {
            ...doc,
            products: [...doc.products, ...selectedStockistProduct],
            gifts: [...doc.gifts, ...selectedStockistGift],
          }
            : doc))
        setSelectedStockistProduct([])
        setSelectedStockistGift([])
      }
      setAddPGPopup(false)
    }

    const handleRemove = (id) => {
      if (activeTab === "Doctor") setDoctorsArray((prev) => prev.filter(doc => doc.doctor._id !== id))
      else if (activeTab === "Chemist") setChemistsArray((prev) => prev.filter(doc => doc.chemist._id !== id))
      else if (activeTab === "Stockist") setStockistsArray((prev) => prev.filter(doc => doc.stockist._id !== id))
    }

    return (
      <>
      <section className="edit-popup edit-popup-top">
        <div className="edit-content edit-content-top">
          <RiCloseCircleLine
              className="edit-popup__close position-absolute"
              style={{ top: "10px", right: "10px" }}
              onClick={() => setAddPopup(false)}
            />
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Add Call Planning</h2>
            
            <div className="edit-form-button-container">
              <button
                className="button-submit-green py-2 rounded">
                {/* <span className="button-submit-green__icon-container">
                  {
                  loading ? <LuLoader2 className="button-submit-green__icon" style={{ ...iconStyle, animation: "spin 1s linear infinite" }} />
                  : <IoIosDoneAll className="button-submit-green__icon" />
                  }
                  </span> */}
                <span className="button-submit-green__text"
                  onClick={handleSubmitFinalCP}
                >{loading ? "Loading..." : "Submit"}</span>
              </button>
            </div>
          </div>

          {/* Tab Selection */}
          <div className="web-app__heading d-flex justify-content-between rounded mt-4 p-3"
            style={{ backgroundColor: "var(--color-primary)", color: "var(--color-white)" }}>
            <div
              className={`tab-item d-flex justify-content-center align-items-center cursor-pointer rounded gap-2 py-2 px-3`}
              style={{ backgroundColor: activeTab === "Doctor" ? "var(--color-success)" : "var(--color-primary)" }}
              onClick={() => setActiveTab("Doctor")}
            >
              Doctor
              {/* <span className="d-flex align-items-center justify-content-center bg-tertiary rounded-circle"
            style={{ height:"20px", width:"20px", backgroundColor: "var(--color-success)" }}>{doctorsArray.length}</span> */}
              <span>({doctorsArray.length})</span>
            </div>
            <div
              className={`tab-item d-flex justify-content-center align-items-center cursor-pointer rounded gap-2 py-2 px-3`}
              style={{ backgroundColor: activeTab === "Chemist" ? "var(--color-success)" : "var(--color-primary)" }}
              onClick={() => setActiveTab("Chemist")}
            >
              Chemist
              {/* <span className="d-flex align-items-center justify-content-center bg-tertiary rounded-circle"
            style={{ height:"20px", width:"20px", backgroundColor: "var(--color-success)" }}>{chemistsArray.length}</span> */}
              <span>({chemistsArray.length})</span>
            </div>
            <div
              className={`tab-item d-flex justify-content-center align-items-center cursor-pointer rounded gap-2 py-2 px-3`}
              style={{ backgroundColor: activeTab === "Stockist" ? "var(--color-success)" : "var(--color-primary)" }}
              onClick={() => setActiveTab("Stockist")}
            >
              Stockist
              {/* <span className="d-flex align-items-center justify-content-center bg-tertiary rounded-circle"
            style={{ height:"20px", width:"20px", backgroundColor: "var(--color-success)" }}>{stockistsArray.length}</span> */}
              <span>({stockistsArray.length})</span>
            </div>
          </div>

          {/* Form */}
          {/* <form> */}
          <div className="edit-form">
            {activeTab === "Doctor" && (
              <div className="">
                <p className="mb-2">Doctor</p>
                <div className="d-flex gap-4 align-items-center">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <Select
                      name="doctor"
                      id="doctor"
                      options={doctorSelect}
                      styles={adminStyles}
                      value={selectedDoctor}
                      placeholder="Select Doctor"
                      onChange={(e) => setSelectedDoctor(e)}
                      className="multi-select-dropdown d-flex justify-content-center"
                    />
                  </div>
                  <IoIosAddCircleOutline
                    className="button-submit-green__icon cursor-pointer"
                    onClick={handleAdd}
                  />
                </div>
                <div className="custom-scrollbar custom-scrollable mt-4 " style={{ maxHeight: '50vh', paddingRight: '20px' }}>
                  <Accordion className="" alwaysOpen>
                    {doctorsArray?.map((e, i) => (
                      <div className="d-flex gap-4 align-items-center">
                        {
                          e?.products?.length > 0 || e?.gifts?.length > 0 ? (
                            <Accordion.Item key={e?.doctor?._id} eventKey={i} className="">
                              <Accordion.Header>
                                {" "}
                                <span className="d-flex align-items-center"
                                  onMouseEnter={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "none";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "inline-block";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "inline-block";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "none";
                                  }}>
                                  <RiNurseFill className="me-3 fs-2 nurse-icon" style={iconStyle2} />
                                  <MdDeleteOutline className="me-3 fs-2 button-delete__icon delete-icon" style={{ ...iconStyle2, display: "none" }}
                                    onClick={() => handleRemove(e?.doctor?._id)}
                                  />
                                  {e?.doctor?.name}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body style={{ backgroundColor: `var(--color-primary-dark)` }}>
                                <h5 className="web-app__heading-blue">
                                  {e?.products?.length > 0 ? "Samples DETAILS" : null}
                                </h5>
                                {e?.products.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.label}
                                    </div>
                                  </div>
                                ))}

                                <h5 className="web-app__heading-blue mt-4">
                                  {e?.gifts?.length > 0 ? "Gift Details" : null}
                                </h5>
                                {e?.gifts.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.label}
                                    </div>
                                  </div>
                                ))}
                                {/* <div className="d-flex justify-content-end">
                    <MdDeleteOutline className="button-delete__icon cursor-pointer " 
                    onClick={() => handleRemove(e?.doctor?._id)}
                    />
                      </div> */}
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            <Accordion.Item key={e?.doctor?._id} eventKey={i} className="">
                              <Accordion.Header key={e._id} className="custom-accordion-header">
                                <span className="d-flex align-items-center"
                                  onMouseEnter={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "none";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "inline-block";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "inline-block";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "none";
                                  }}>
                                  <RiNurseFill className="me-3 fs-2 nurse-icon" style={iconStyle2} />
                                  <MdDeleteOutline className="me-3 fs-2 button-delete__icon delete-icon" style={{ ...iconStyle2, display: "none" }}
                                    onClick={() => handleRemove(e?.doctor?._id)}
                                  />
                                  {e?.doctor?.name}
                                </span>
                              </Accordion.Header>
                            </Accordion.Item>
                          )
                        }
                        <IoIosAddCircleOutline
                          className="button-submit-green__icon cursor-pointer"
                          onClick={() => {
                            setCurrentEntity(e?.doctor?._id)
                            setSelectedDoctorProduct(e?.products || [])
                            setSelectedDoctorGift(e?.gifts || [])
                            setAddPGPopup(true)
                          }}
                        />
                      </div>
                    ))}
                  </Accordion>
                </div>
              </div>
              //  </div>
            )}
            {activeTab === "Chemist" && (
              <div className="">
                <p className="mb-2">Chemist</p>
                <div className="d-flex gap-4 align-items-center">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <Select
                      name="chemist"
                      id="chemist"
                      options={chemistSelect}
                      styles={adminStyles}
                      value={selectedChemist}
                      placeholder="Select Chemist"
                      onChange={(e) => setSelectedChemist(e)}
                      className="multi-select-dropdown d-flex justify-content-center"
                    />
                  </div>
                  <IoIosAddCircleOutline
                    className="button-submit-green__icon cursor-pointer"
                    onClick={handleAdd}
                  />
                </div>
                <div className="custom-scrollbar custom-scrollable mt-4 " style={{ maxHeight: '50vh', paddingRight: '20px' }}>
                  <Accordion className="" alwaysOpen>
                    {chemistsArray.map((e, i) => (
                      <div className="d-flex gap-4 align-items-center">
                        {
                          e?.products?.length > 0 || e?.gifts?.length > 0 ? (
                            <Accordion.Item key={e?.chemist?._id} eventKey={i} className="">
                              <Accordion.Header>
                                {" "}
                                <span className="d-flex align-items-center"
                                  onMouseEnter={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "none";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "inline-block";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "inline-block";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "none";
                                  }}>
                                  <RiNurseFill className="me-3 fs-2 nurse-icon" style={iconStyle2} />
                                  <MdDeleteOutline className="me-3 fs-2 button-delete__icon delete-icon" style={{ ...iconStyle2, display: "none" }}
                                    onClick={() => handleRemove(e?.chemist?._id)}
                                  />
                                  {e?.chemist?.businessName}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body style={{ backgroundColor: `var(--color-primary-dark)` }}>
                                <h5 className="web-app__heading-blue">
                                  {e?.products.length > 0 ? "Samples DETAILS" : null}
                                </h5>
                                {e?.products.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.label}
                                    </div>
                                  </div>
                                ))}

                                <h5 className="web-app__heading-blue mt-4">
                                  {e?.gifts.length > 0 ? "Gift Details" : null}
                                </h5>
                                {e?.gifts.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.label}
                                    </div>
                                  </div>
                                ))}
                                {/* <div className="d-flex justify-content-end">
                    <MdDeleteOutline className="button-delete__icon cursor-pointer " 
                    onClick={() => handleRemove(e?.chemist?._id)}
                    />
                      </div> */}
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            <Accordion.Item key={e?.chemist?._id} eventKey={i} className="">
                              <Accordion.Header key={e._id} className="custom-accordion-header">
                                <span className="d-flex align-items-center"
                                  onMouseEnter={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "none";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "inline-block";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "inline-block";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "none";
                                  }}>
                                  <RiNurseFill className="me-3 fs-2 nurse-icon" style={iconStyle2} />
                                  <MdDeleteOutline className="me-3 fs-2 button-delete__icon delete-icon" style={{ ...iconStyle2, display: "none" }}
                                    onClick={() => handleRemove(e?.chemist?._id)}
                                  />
                                  {e?.chemist?.businessName}
                                </span>
                              </Accordion.Header>
                            </Accordion.Item>
                          )
                        }
                        <IoIosAddCircleOutline
                          className="button-submit-green__icon cursor-pointer"
                          onClick={() => {
                            setCurrentEntity(e?.chemist?._id)
                            setSelectedChemistProduct(e?.products || [])
                            setSelectedChemistGift(e?.gifts || [])
                            setAddPGPopup(true)
                          }}
                        />
                      </div>
                    ))}
                  </Accordion>
                </div>
              </div>
            )}
            {activeTab === "Stockist" && (
              <div className="">
                <p className="mb-2">Stockist</p>
                <div className="d-flex gap-4 align-items-center">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <Select
                      name="stockist"
                      id="stockist"
                      options={stockistSelect}
                      styles={adminStyles}
                      value={selectedStockist}
                      placeholder="Select Stockist"
                      onChange={(e) => setSelectedStockist(e)}
                      className="multi-select-dropdown d-flex justify-content-center"
                    />
                  </div>
                  <IoIosAddCircleOutline
                    className="button-submit-green__icon cursor-pointer"
                    onClick={handleAdd}
                  />
                </div>
                <div className="custom-scrollbar custom-scrollable mt-4 " style={{ maxHeight: '50vh', paddingRight: '20px' }}>
                  <Accordion className="" alwaysOpen>
                    {stockistsArray.map((e, i) => (
                      <div className="d-flex gap-4 align-items-center">
                        {
                          e?.products?.length > 0 || e?.gifts?.length > 0 ? (
                            <Accordion.Item key={e.stockist._id} eventKey={i} className="">
                              <Accordion.Header>
                                {" "}
                                <span className="d-flex align-items-center"
                                  onMouseEnter={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "none";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "inline-block";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "inline-block";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "none";
                                  }}>
                                  <RiNurseFill className="me-3 fs-2 nurse-icon" style={iconStyle2} />
                                  <MdDeleteOutline className="me-3 fs-2 button-delete__icon delete-icon" style={{ ...iconStyle2, display: "none" }}
                                    onClick={() => handleRemove(e?.stockist?._id)}
                                  />
                                  {e?.stockist?.businessName}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body style={{ backgroundColor: `var(--color-primary-dark)` }}>
                                <h5 className="web-app__heading-blue mt-4">
                                  {e?.products.length > 0 ? "Samples DETAILS" : null}
                                </h5>
                                {e?.products.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.label}
                                    </div>
                                  </div>
                                ))}

                                <h5 className="web-app__heading-blue">
                                  {e?.gifts.length > 0 ? "Gift Details" : null}
                                </h5>
                                {e?.gifts.map((elem, i) => (
                                  <div key={i} className="acc-body-card d-flex mt-4">
                                    <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                                    <div className="acc-body-card-content d-flex align-items-center px-4">
                                      {elem?.label}
                                    </div>
                                  </div>
                                ))}
                                {/* <div className="d-flex justify-content-end">
                    <MdDeleteOutline className="button-delete__icon cursor-pointer " 
                    onClick={() => handleRemove(e?.stockist?._id)}
                    />
                      </div> */}
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            <Accordion.Item key={e?.stockist?._id} eventKey={i} className="">
                              <Accordion.Header key={e._id} className="custom-accordion-header">
                                <span className="d-flex align-items-center"
                                  onMouseEnter={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "none";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "inline-block";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.querySelector(".nurse-icon").style.display = "inline-block";
                                    e.currentTarget.querySelector(".delete-icon").style.display = "none";
                                  }}>
                                  <RiNurseFill className="me-3 fs-2 nurse-icon" style={iconStyle2} />
                                  <MdDeleteOutline className="me-3 fs-2 button-delete__icon delete-icon" style={{ ...iconStyle2, display: "none" }}
                                    onClick={() => handleRemove(e?.stockist?._id)}
                                  />
                                  {e?.stockist?.businessName}
                                </span>
                              </Accordion.Header>
                            </Accordion.Item>
                          )
                        }
                        <IoIosAddCircleOutline
                          className="button-submit-green__icon cursor-pointer"
                          onClick={() => {
                            setCurrentEntity(e?.stockist?._id)
                            setSelectedStockistProduct(e?.products || [])
                            setSelectedStockistGift(e?.gifts || [])
                            setAddPGPopup(true)
                          }}
                        />
                      </div>
                    ))}
                  </Accordion>
                </div>
              </div>
            )}
          </div>

          
        </div>
      </section>
      {addPGPopup ? <section className="edit-popup edit-popup-top">
            <div className="edit-content edit-content-top">
              <div className="edit-popup__heading">
                <h2 className="web-app__heading">Add Details</h2>
                <RiCloseCircleLine
                  className="edit-popup__close"
                  onClick={() => {
                    setAddPGPopup(false)
                  }}
                />
              </div>


              {
                activeTab === "Doctor" && (
                  <div className="d-flex justify-content-between gap-4">
                    <div className="w-50">
                      <p className="mb-2">Product</p>
                      <Select
                        isMulti
                        name="product"
                        id="product"
                        options={productsSelect}
                        styles={adminStyles}
                        value={selectedDoctorProduct}
                        placeholder="Select Product"
                        onChange={(e) => setSelectedDoctorProduct(e)}
                        className="multi-select-dropdown d-flex justify-content-center"
                      />
                    </div>
                    <div className="w-50">
                      <p className="mb-2">Gift</p>
                      <Select
                        isMulti
                        name="gift"
                        id="gift"
                        options={giftsSelect}
                        styles={adminStyles}
                        value={selectedDoctorGift}
                        placeholder="Select Gift"
                        onChange={(e) => setSelectedDoctorGift(e)}
                        className="multi-select-dropdown d-flex justify-content-center"
                      />
                    </div>
                  </div>
                )
              }
              {
                activeTab === "Chemist" && (
                  <div className="d-flex justify-content-between gap-4">
                    <div className="w-50">
                      <p className="mb-2">Product</p>
                      <Select
                        isMulti
                        name="product"
                        id="product"
                        options={productsSelect}
                        styles={adminStyles}
                        value={selectedChemistProduct}
                        placeholder="Select Product"
                        onChange={(e) => setSelectedChemistProduct(e)}
                        className="multi-select-dropdown d-flex justify-content-center"
                      />
                    </div>
                    <div className="w-50">
                      <p className="mb-2">Gift</p>
                      <Select
                        isMulti
                        name="gift"
                        id="gift"
                        options={giftsSelect}
                        styles={adminStyles}
                        value={selectedChemistGift}
                        placeholder="Select Gift"
                        onChange={(e) => setSelectedChemistGift(e)}
                        className="multi-select-dropdown d-flex justify-content-center"
                      />
                    </div>
                  </div>
                )
              }
              {
                activeTab === "Stockist" && (
                  <div className="d-flex justify-content-between gap-4">
                    <div className="w-50">
                      <p className="mb-2">Product</p>
                      <Select
                        isMulti
                        name="product"
                        id="product"
                        options={productsSelect}
                        styles={adminStyles}
                        value={selectedStockistProduct}
                        placeholder="Select Product"
                        onChange={(e) => setSelectedStockistProduct(e)}
                        className="multi-select-dropdown d-flex justify-content-center"
                      />
                    </div>
                    <div className="w-50">
                      <p className="mb-2">Gift</p>
                      <Select
                        isMulti
                        name="gift"
                        id="gift"
                        options={giftsSelect}
                        styles={adminStyles}
                        value={selectedStockistGift}
                        placeholder="Select Gift"
                        onChange={(e) => setSelectedStockistGift(e)}
                        className="multi-select-dropdown d-flex justify-content-center"
                      />
                    </div>
                  </div>
                )
              }


              <div className="edit-form-button-container">
                <button
                  type="submit"
                  className="button-submit-green mt-4"
                  onClick={handleAddPG}
                >
                  <span className="button-submit-green__icon-container">
                    <MdOutlineAdd className="button-submit-green__icon" style={{ fontSize: "1.5em" }} />
                  </span>
                  <span className="button-submit-green__text">Add</span>
                </button>
              </div>
            </div>
          </section> : null}
      </>
    );
  }

  const DeletePopup = ({data}) => {

    const handleDelete = async () => {  
      try {
        await deleteCallPlanning(data?.id)
        customToast.success("Call plan deleted successfully")
      } catch (error) {
        customToast.error("Error in deleting call plan!")
      }
    }
    
    return (
          <section className="edit-popup">
            <div className="edit-content">
              <div className="edit-popup__heading">
                <h2 className="web-app__heading">Delete Call Plan</h2>
                <RiCloseCircleLine
                  className="edit-popup__close"
                  onClick={() => setShowDelete(false)}
                />
              </div>
    
                <div className="edit-form">
                  <div>
                    <label htmlFor="distance">
                      Do you really want to delete this call plan?
                    </label>
                  </div>
                </div>
    
                <div className="edit-form-button-container">
                  {/* <button
                    type="submit"
                    
                    className="button-submit-green mt-4"
                  >
                    <span className="button-submit-green__icon-container">
                      <MdOutlineEdit className="button-submit-green__icon" />
                    </span>
                    <span className="button-submit-green__text">Edit</span>
                  </button> */}
                  <button
                    
                    onClick={() => handleDelete()}
                    className="button-delete mt-4"
                  >
                    <span className="button-delete__icon-container">
                      <MdDeleteOutline className="button-delete__icon" />
                    </span>
                    <span className="button-delete__text">Delete</span>
                  </button>
                </div>
            </div>
          </section>
        );
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'long' });

          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text">{formatDate(props.row.original?.date)}</span>
          ) : props.row.original?.isApproved === 0 ? (
            <span className="pending-text">{formatDate(props.row.original?.date)}</span>
          ) : props.row.original?.isApproved === -1 ? (
            <span className="rejected-text">{formatDate(props.row.original?.date)}</span>
          ) : props.row.original?.leave ? (
            <span className="text-opacity">{formatDate(props.row.original?.date)}</span>
          ) : props.row.original?.holiday ? (
            <span className="text-opacity">{formatDate(props.row.original?.date)}</span>
          ) : day === "Sunday" ? (
            props?.row?.original._id ? <span className="notsubmitted-text">{formatDate(props.row.original?.date)}</span>
              : <span className="text-opacity">{formatDate(props.row.original?.date)}</span>
          )
            : <span className="notsubmitted-text">{formatDate(props.row.original?.date)}</span>
        }
      },
      {
        Header: "Day",
        accessor: "day",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'long' });
          // if(day === "Sunday") return <span className="text-opacity">{day}</span>

          return props.row.original?.leave ? (
            <span className="text-opacity">Leave</span>
          ) : props.row.original?.holiday ? (
            <span className="text-opacity">Holiday</span>
          ) : day === "Sunday" ? (
            props?.row?.original._id ? <span className="notsubmitted-text">{day}</span>
              : <span className="text-opacity">{day}</span>
          ) :
            <span className="notsubmitted-text">{day}</span>
          // return new Date(props?.row?.original?.date).toLocaleString("en-us", {weekday:"long"})

        }
      },
      {
        Header: "Doctors",
        accessor: "doctors",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.doctors?.length ?? "-"
        }
      },
      {
        Header: "Chemists",
        accessor: "chemists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.chemists?.length ?? "-"
        }
      },
      {
        Header: "Stockists",
        accessor: "stockists",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return props?.row?.original?.stockists?.length ?? "-"
        }
      },
      {
        Header: "View",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'long' });
          return props.row.original?.leave ? (
            <AiOutlineStop className="icon-opacity" />
          ) : props.row.original?.holiday ? (
            <AiOutlineStop className="icon-opacity" />
          ) : day === "Sunday" ? (
            props?.row?.original._id ? <Link
              to={props?.row?.original?._id}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
              : <AiOutlineStop className="icon-opacity" />
          ) : props?.row?.original._id ?
            <Link
              to={props?.row?.original?._id}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
            : <AiOutlineStop className="icon-opacity" />
        },
      },
      {
        Header: "Action",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'long' });
          return props?.row?.original._id ? <PiDotsThreeCircleLight style={iconStyle} onClick={() => {
            setAddForm(props.row.original, selectedUser)
            setShowDelete(true)
          }}/>
            : props.row.original?.leave ? (
              <AiOutlineStop className="icon-opacity" />
            ) : props.row.original?.holiday ? (
              <AiOutlineStop className="icon-opacity" />
            ) : (day === "Sunday") ? (
              sundayWorking === "true" ? <IoIosAddCircleOutline style={iconStyle} onClick={() => {
                setAddForm(props.row.original, selectedUser)
                setAddPopup(true)
              }} />
                : <AiOutlineStop className="icon-opacity" />
            ) :
              <IoIosAddCircleOutline style={iconStyle} onClick={() => {
                setAddForm(props.row.original, selectedUser)
                setAddPopup(true)
              }} />
        }
      }
    ],
    [selectedUser]
  )
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    viewTargets("doctor", { showApproved: true }).then((response) => {
      setDoctors(response?.data);
    }).catch(e => console.log(e));
    viewTargets("chemist", { showApproved: true }).then((response) => {
      setChemists(response?.data);
    }).catch(e => console.log(e));
    viewTargets("stockist", { showApproved: true }).then((response) => {
      setStockists(response?.data);
    }).catch(e => console.log(e));

    viewProductV1("").then((response) => {
      setProducts(response?.data);
    });
    viewGifts().then((response) => {
      setGifts(response?.data);
    });
  }, [selectedUser])

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const doctorSelect = useMemo(
    () => generateSelectData(doctors, "name"),
    [doctors]
  );
  const chemistSelect = useMemo(
    () => generateSelectData(chemists, "businessName"),
    [chemists]
  );
  const stockistSelect = useMemo(
    () => generateSelectData(stockists, "businessName"),
    [stockists]
  );
  const productsSelect = useMemo(
    () => generateSelectData(products, "name"),
    [products]
  );

  const giftsSelect = useMemo(
    () => generateSelectData(gifts, "gift"),
    [gifts]
  );

  useEffect(() => {
    const response = getMonthlyCallPlanning({ month: month?.value, year: year?.label, selectedUser: selectedUser?.value }).then((res) => {
      setCPData([...res.data])
    })

  }, [month, year, selectedUser])

  const tableData = useMemo(() => serialise(cpData), [cpData]);

  return (
    <>
      <form >
        <div className="expense__filters">
          <div className="expense__filters-duration">
            <div className="me-4">
              <p className="mb-2">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Select
                options={monthOptions}
                styles={dcrStyles}
                value={month}
                onChange={(e) => {
                  setMonth({ ...e });
                }}
              />
            </div>
            <div className="me-4">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={dcrStyles}
                options={yearOptions}
                placeholder="Select Year"
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
            </div>

          </div>
          <div className="me-4">
            {loggedIn?.user?.des !== 121 && (
              <div>
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            )
              // : (
              //   <button
              //     type="button"
              //     className="mt-5 ms-auto button-blue-gradient"
              //     onClick={(e) => {
              //       if (!month) return customToast.error("Please select a month");
              //       else if (!year)
              //         return customToast.error("Please select a year");
              //     }}
              //   >
              //     Submit
              //   </button>)
            }
          </div>
        </div>
        <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
          <h4>
            <span className="tp-indicator-9 me-2"></span>Approved
          </h4>
          <h4>
            <span className="tp-indicator-10 me-2"></span>Rejected
          </h4>
          <h4>
            <span className="tp-indicator-5 me-2"></span>Pending
          </h4>
          <h4>
            <span className="tp-indicator-12 me-2"></span>Leave
          </h4>
        </div>
      </form>
      <div className="filter-table">
        <Table defaultPageSize={50} columns={columns} data={tableData} empl={selectedUser ? selectedUser : loggedIn?.user} month={month} fileSrc="CallPlanning" />
        {addPopup ? <AddPopup data={data} /> : null}
        {showDelete ? <DeletePopup data={data} /> : null}
      </div>
    </>
  );
};

export default CallPlanningForm;